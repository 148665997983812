import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams, Outlet, } from "react-router-dom";

import { auth } from '../../firebase';
import { getDataAction } from '../../redux/actions/getDataActions';
import { getDataAuthAction } from '../../redux/actions/getDataAuthActions';
import { setLoggedIn, logout } from "../../redux/actions/miscActions";

import ConversationBox from './ConversationBox';
import SendBox from './SendBox';

import { formatDate } from '../../helpers';

import './style.css';

function Engage(props) {
  const userState = useSelector((state) => state.userReducer);
  const engageState = useSelector((state) => state.engageReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();

  const getGuestData = (startDate, endDate) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log(`getGuestData`);
    }
    if (userState.user.loggedIn && auth.currentUser) {
      dispatch(getDataAuthAction({
        op: 'get_guest',
        params: {
          location__id: urlParams.locationidentifier,
          guest_id: urlParams.guestidentifier, // '610ac959f933820008bb4db9', // '61cacbbe27872a00087a1a27',
        },
      }));

      // auth.currentUser
      //   .getIdToken(false)
      //   .then((idToken) => {
      //     dispatch(getDataAction({
      //       op: 'get_guest',
      //       params: {
      //         location__id: 'wZYeknJ4QgyGXj7Jm',
      //         guest_id: urlParams.guestidentifier, // '610ac959f933820008bb4db9', // '61cacbbe27872a00087a1a27',
      //       },
      //       idToken,
      //     }));
      //   })
      //   .catch((err) => {
      //   // firebase error
      //     if (process.env.NODE_ENV !== 'production') {
      //       console.log(`firebase err--------------------: ${err}`);
      //     }
      //     // sendEmailAlert({
      //     //   app: gtconfig.APP_NAME,
      //     //   subject: `${gtconfig.APP_NAME} - getGuestListAction Error - firebase`,
      //     //   message: 'Firebase error',
      //     //   error: err.toString(),
      //     //   store: JSON.stringify(getState()),
      //     // });
      //     // dispatch({
      //     //   type: `${opMap[payload.op]}_ERROR`,
      //     //   payload: {
      //     //     ...payload,
      //     //     errorMessage: err.toString(),
      //     //   },
      //     // });
      //   });
    }
  };

  useEffect(() => {
    if (userState.user.uid && urlParams.guestidentifier && userState.user.uid !== urlParams.guestidentifier) {
      if (process.env.NODE_ENV !== 'production') {
        console.log(`wrong access, logout...`);
        // `/${urlParams.locationidentifier}/engage/logout`
        navigate(`/${urlParams.locationidentifier}/engage/logout`);
        // dispatch(setLoggedIn({
        //   loggedIn: false,
        //   loggedOut: true,
        // }));
        // dispatch(logout({
        //   params: {
        //     uid: userState.user.uid,
        //   },
        // }));
        // navigate(`/${urlParams.locationidentifier}/welcome`);
      }
    }
  }, []);

  useEffect(() => {
    if (userState.user.uid && urlParams.guestidentifier && userState.user.uid === urlParams.guestidentifier) {
      getGuestData();
    }
  }, []);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('loggedIn changed: ', userState.user.loggedIn);
    }
    if (!userState.user.loggedIn) {
      navigate(`/${urlParams.locationidentifier}/engage/login`);
    }
  }, [userState.user.loggedIn])

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      console.log(`guestidentifier changed`);
    }
    // nkcheck
    // if (userState.user.uid !== urlParams.guestidentifier) {
    //   if (process.env.NODE_ENV !== 'production') {
    //     console.log('Engage guestidentifier uid mismatch : ', urlParams.guestidentifier, ' # ', userState.user.uid);
    //     navigate(`/${urlParams.locationidentifier}/engage/logout`);
    //   }
    // } else {
      if (userState.user.uid && urlParams.guestidentifier && userState.user.uid === urlParams.guestidentifier) {
        getGuestData();
      }
    // }
  }, [urlParams.guestidentifier]); // auth.currentUser

  const renderStayInfo = (guestData) => {
    return (
      <div className="stInfBx">
        <div className="etry">
          <span className="val">{formatDate(guestData.check_in_date)}</span>
          <span className="lbl">Check-In Date</span>
        </div>
        <div className="etry">
          <span className="val">{formatDate(guestData.check_out_date)}</span>
          <span className="lbl">Check-Out Date</span>
        </div>
        {
          guestData.room_number && !guestData.room_type &&
          <div className="etry">
            <span className="val">{guestData.room_number}</span>
            <span className="lbl">Room Number</span>
          </div>
        }
        {
          !guestData.room_number && guestData.room_type &&
          <div className="etry">
            <span className="val">{guestData.room_type}</span>
            <span className="lbl">Room Type</span>
          </div>
        }
      </div>
    )
  }

  if (process.env.NODE_ENV !== 'production') {
    console.log('Engage urlParams : ', urlParams);
  }

  // return null;

  if (!userState.user.loggedIn || !userState.user.uid || !auth.currentUser) {
    return (
      <div className="mb eng">
        <div className="enWrp">
          <div className="gPnlLdng">
            <i className="fa fa-spinner fa-spin" />
          </div>
        </div>
      </div>
    );
  }

  // if (!userState.user.uid) return null;

  if (engageState.guest_data && engageState.guest_data.getGuestInProgress) {
    return (
      <div className="mb eng">
        <div className="enWrp">
          <div className="gPnlLdng">
            <i className="fa fa-spinner fa-spin" />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="mb eng">
      <div className="enWrp">
        { renderStayInfo(engageState.guest_data.data || {}) }
        <ConversationBox />
        <SendBox />
      </div>
    </div>
  );
}

export default Engage;
