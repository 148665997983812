import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams, Outlet, useLocation } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Pusher from 'pusher-js';

import setWindowAttributes from '../../redux/actions/windowActions';
import { handleEngagePusherMessage } from '../../redux/actions/miscEngageActions';
import { getDataAction, getDataClearAction } from '../../redux/actions/getDataActions';

import TopBar from '../TopBar';
import BottomBar from '../BottomBar';
import Welcome from '../Welcome';
import GuideWrapper from '../Guide/GuideWrapper';
import EngageWrapper from '../EngageWrapper';

import { sendEmailAlert } from '../../helpers';

import GTLogo from '../../img/gtlogo-white-small.png';

import './style.css';

const gtconfig = require('../../gtconfig');

// Create pusher
let pusher = new Pusher(gtconfig.PUSHER_APP_KEY, {
  cluster: gtconfig.PUSHER_APP_CLUSTER,
  encrypted: true,
});
let channelLocation = null;
let channelUser = null;


function LocationWrapper() {
  const windowState = useSelector((state) => state.windowReducer);
  const userState = useSelector((state) => state.userReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const engageState = useSelector((state) => state.engageReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let location = useLocation();
  let urlParams = useParams();

  const [curLocationMId, setCurLocationMId] = useState(urlParams.locationidentifier); // nkcheck
  const [curLocationId, setCurLocationId] = useState(''); // nkcheck

  const clearStatus = () => {
    dispatch(getDataClearAction({
      op: 'get_location_info',
      params: {
        location__id: urlParams.locationidentifier,
      },
    }));
  };

  const getLocationData = () => {
    const params = {
      location__id: urlParams.locationidentifier,
    };
    if (userState.user && userState.user.uid && userState.user.loggedIn) {
      params.guest_id = userState.user.uid;
    }
    dispatch(getDataAction({
      op: 'get_location_info',
      params,
    }));
  };

  useEffect(() => {
    clearStatus();
    console.log('LocationWrapper locationidentifier changed: ', urlParams.locationidentifier);
    getLocationData();
  }, [urlParams.locationidentifier]);

  useEffect(() => {
    if (locationState.locations[urlParams.locationidentifier] && !locationState.locations[urlParams.locationidentifier].getDataInProgress &&
      locationState.locations[urlParams.locationidentifier].getDataStatus &&
      locationState.locations[urlParams.locationidentifier].getDataStatus.success
    ) {
      if (locationState.locations[urlParams.locationidentifier].location_data &&
        locationState.locations[urlParams.locationidentifier].location_data.location_id
      ) {
        setCurLocationId(locationState.locations[urlParams.locationidentifier].location_data.location_id);
      }
      clearStatus();
    }
  }, [locationState.locations[urlParams.locationidentifier]]);

  // useEffect(() => {
  //   console.log('LocationWrapper');
  //   getLocationData();
  // }, []);

  useEffect(() => {
    function handleResize() {
      dispatch(setWindowAttributes({
        windowWidth: document.body.clientWidth,
        windowHeight: window.innerHeight,
      }));
    }

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (location.pathname === '/') {
      navigate(`/welcome`);
    }
  }, []);

  // pusher
  useEffect(() => {
    if (curLocationMId) {
      const channelName = `gt-guest-webapp-loc-${curLocationMId}`; // nkcheck
      if (process.env.NODE_ENV !== 'production') {
        console.log('LocationWrapper pusher channelName: ', channelName);
      }
      channelLocation = pusher.subscribe(channelName);
      if (process.env.NODE_ENV !== 'production') {
        console.log('LocationWrapper pusher channelLocation: ', channelLocation);
      }
      pusher.connection.bind('connected', () => {
        if (process.env.NODE_ENV !== 'production') {
          console.log('LocationWrapper pusher connected');
        }
      });
      pusher.connection.bind('error', (error) => {
        if (process.env.NODE_ENV !== 'production') {
          console.log('LocationWrapper pusher connection error: ', error);
        }
        setTimeout(() => {
          if (pusher.connection.state === 'unavailable' || pusher.connection.state === 'failed') {
            if (process.env.NODE_ENV !== 'production') {
              console.log('LocationWrapper attempt reconnection');
            }
            pusher = new Pusher(gtconfig.PUSHER_APP_KEY, {
              cluster: gtconfig.PUSHER_APP_CLUSTER,
              encrypted: true,
            });
          }
        }, 5000);
      });
      pusher.connection.bind('unavailable', () => {
        setTimeout(() => {
          if (process.env.NODE_ENV !== 'production') {
            console.log('LocationWrapper pusher.connection.state: ', pusher.connection.state);
          }
          if (pusher.connection.state === 'unavailable' || pusher.connection.state === 'failed') {
            if (process.env.NODE_ENV !== 'production') {
              console.log('LocationWrapper attempt reconnection');
            }
            pusher = new Pusher(gtconfig.PUSHER_APP_KEY, {
              cluster: gtconfig.PUSHER_APP_CLUSTER,
              encrypted: true,
            });
          }
        }, 5000);
      });
      pusher.connection.bind('failed', () => {
        setTimeout(() => {
          if (process.env.NODE_ENV !== 'production') {
            console.log('LocationWrapper pusher.connection.state: ', pusher.connection.state);
          }
          if (pusher.connection.state === 'unavailable' || pusher.connection.state === 'failed') {
            if (process.env.NODE_ENV !== 'production') {
              console.log('LocationWrapper attempt reconnection');
            }
            pusher = new Pusher(gtconfig.PUSHER_APP_KEY, {
              cluster: gtconfig.PUSHER_APP_CLUSTER,
              encrypted: true,
            });
          }
        }, 5000);
      });
      pusher.connection.bind('disconnected', () => {
        if (process.env.NODE_ENV !== 'production') {
          console.log('LocationWrapper pusher disconnected');
        }
      });

      channelLocation.bind('pusher:subscription_succeeded', () => {
        if (process.env.NODE_ENV !== 'production') {
          console.log('LocationWrapper pusher:subscription_succeeded');
        }
        // get unread count data if is stale
        if (process.env.NODE_ENV !== 'production') {
          console.log('LocationWrapper pusher:subscription_succeeded');
        }
      });
      channelLocation.bind('pusher:subscription_error', (status) => {
        if (process.env.NODE_ENV !== 'production') {
          console.log('LocationWrapper pusher:subscription_error: ', status);
        }
        // sendEmailAlert({
        //   app: gtconfig.APP_NAME,
        //   subject: `${gtconfig.APP_NAME} - pusher subscription_error error - ${props.userprops.user.uid}`,
        //   message: JSON.stringify({ message: 'pusher subscription_error error' }),
        //   error: JSON.stringify({ status }),
        //   store: JSON.stringify({ userprops: props.userprops, locationprops: props.locationprops }),
        // });
      });
      channelLocation.bind('message', (data) => {
        if (process.env.NODE_ENV !== 'production') {
          console.log('LocationWrapper pusher data received: ', data);
        }
        const { prd } = data;
        if (prd === 'engage') {
          processEngagePusherData(data);
        }
      });
      return () => {
        // unsubscribe();
        if (process.env.NODE_ENV !== 'production') {
          console.log('LocationWrapper pusher pusherChannel unsubscribe / unbind: ', channelLocation);
        }
        channelLocation.unbind();
        pusher.unsubscribe();

      };
    }
  }, [curLocationMId]); // nkcheck - locationMId


  const notifyMe = (notificationData) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('LocationWrapper notifyMe: ', notificationData);
    }
    const { title, options } = notificationData;
    // Let's check if the browser supports notifications
    if (!('Notification' in window)) {
      alert('This browser does not support system notifications');
    } else {
      if (process.env.NODE_ENV !== 'production') {
        console.log('LocationWrapper notifyMe Notification: ', Notification);
        console.log('LocationWrapper notifyMe Notification.permission: ', Notification.permission);
      }
      // Let's check whether notification permissions have already been granted
      if (Notification.permission === 'granted') {
        // If it's okay let's create a notification
        // options = { body: msg, tag: id, icon: '/img/gtlogo.png', data: data }
        try {
          var notification = new Notification(title, options);
          notification.onclick = function() {
            if (process.env.NODE_ENV !== 'production') {
              console.log('LocationWrapper this.data: ',this.data);
            }
            const { message, type } = this.data;
            let notificationClickUrl = '/engage';
            if(notificationClickUrl){
              navigate(notificationClickUrl);
            }
            notification.close();
          };
        } catch (e) {
          if (process.env.NODE_ENV !== 'production') {
            console.log('LocationWrapper Notification err: ',e);
          }
        }
      } else {
        // Otherwise, we need to ask the user for permission
        if (Notification.permission !== 'denied') {
          Notification.requestPermission((permission) => {
            // If the user accepts, let's create a notification
            if (permission === 'granted') {
              try {
                let notification = new Notification(title, options);
                notification.onclick = function() {
                  if (process.env.NODE_ENV !== 'production') {
                    console.log('LocationWrapper this.data: ',this.data);
                  }
                  const { message, type } = this.data;
                  let notificationClickUrl = '/engage';
                  if(notificationClickUrl){
                    navigate(notificationClickUrl);
                  }
                  notification.close();
                };
              } catch (e) {
                if (process.env.NODE_ENV !== 'production') {
                  console.log('LocationWrapper Notification err: ',e);
                }
              }
            }
          });
        }
      }
    }
  };

  const processEngagePusherData = (data) => {
    if (!curLocationId) {
      if (process.env.NODE_ENV !== 'production') {
        console.log('LocationWrapper curLocationId not set');
      }
      return;
    }
    try {
      if (!engageState.guest_data) return null;
      const guestData = engageState.guest_data.data ? { ...engageState.guest_data.data } : {};


      const { type } = data;
      const innerData = data.data;
      if (process.env.NODE_ENV !== 'production') {
        console.log('LocationWrapper pusher data innerData: ', innerData);
      }
      if (innerData) {
        if (innerData &&
          (innerData.location_id || (innerData.message && innerData.message.location_id) ||
            (innerData.messages && innerData.messages.length && innerData.messages[0].location_id)
          )
        ) {
          if (innerData.location_id === curLocationId ||
            (innerData.message && innerData.message.location_id === curLocationId) ||
            (innerData.messages && innerData.messages.length && innerData.messages[0].location_id === curLocationId)
          ) {
            // show notifications only in below cases
            if (
              type === 'guest-message-create' ||
              type === 'outside-guest-message-create'
            ) {
              const { message, outside_guest } = innerData;
              if (message) {
                const { msg, name } = message;
                const { _id, location_id, guest_id, outside_guest_id } = message;
                if (process.env.NODE_ENV !== 'production') {
                  console.log('LocationWrapper guest_id: ', guest_id);
                  console.log('LocationWrapper outside_guest_id: ', outside_guest_id);
                }
                let notificationClickUrl = null;
                if (message.type === 2 && _id && location_id && (guest_id || outside_guest_id)) {
                  let guestTypeKey = null;
                  notificationClickUrl = `/engage`;
                  if (process.env.NODE_ENV !== 'production') {
                    console.log('LocationWrapper guestTypeKey: ', guestTypeKey);
                    console.log('LocationWrapper notificationClickUrl: ', notificationClickUrl);
                  }
                  // if (guestTypeKey && getCategoryIdByGuestKey[guestTypeKey]) {
                    const notificationData = {
                      title: name ? `New mesage from ${name}` : 'New Message',
                      options: {
                        body: msg,
                        tag: 'msg_' + message._id,
                        icon: GTLogo,
                        data: {
                          message,
                          type,
                          actionUrl: notificationClickUrl,
                        },
                      },
                    };
                    notifyMe(notificationData);
                  // }
                }
              } // end of if message
            }

            dispatch(handleEngagePusherMessage({
              ...data,
              locationidentifier: curLocationMId,
            }));
          } else {
            // nkcheck
            // log error
            // console.log('innerData location_id mismatch');
            sendEmailAlert({
              app: gtconfig.APP_NAME,
              subject: `${gtconfig.APP_NAME} - handleMessage Error - ${userState.user.uid}`,
              message: JSON.stringify({ data }),
              error: 'innerData location_id mismatch',
              store: JSON.stringify({ userState, engageState }),
            });
          }
        } else {
          // nkcheck
          // log error
          // console.log('innerData location_id missing');
          sendEmailAlert({
            app: gtconfig.APP_NAME,
            subject: `${gtconfig.APP_NAME} - handleMessage Error - ${userState.user.uid}`,
            message: JSON.stringify({ data }),
            error: 'innerData location_id missing',
            store: JSON.stringify({ userState, engageState }),
          });
        }
      } else {
        // console.log('innerData missing');
        sendEmailAlert({
          app: gtconfig.APP_NAME,
          subject: `${gtconfig.APP_NAME} - handleMessage Error - ${userState.user.uid}`,
          message: JSON.stringify({ data }),
          error: 'innerData missing',
          store: JSON.stringify({ userState, engageState }),
        });
      }
    } catch (e) {
      if (process.env.NODE_ENV !== 'production') {
        console.log('LocationWrapper processEngagePusherData e: ', e);
      }
    }
  };

  if (process.env.NODE_ENV !== 'production') {
    console.log('LocationWrapper urlParams : ', urlParams);
  }

  const { locationidentifier } = urlParams;
  if (!locationidentifier) return null;

  if (!locationState.locations[locationidentifier]) return null;
  const { location_data, guide } = locationState.locations[locationidentifier];
  if (!location_data || !location_data.name) return null;


  const cStyle = {
    height: windowState.window.windowHeight,
  };

  if (locationState.locations[locationidentifier].getDataInProgress) {
    return (
      <div className="gPnlLdng">
        <i className="fa fa-spinner fa-spin" />
      </div>
    );
  }

  if (!curLocationId) return null;


  return (
    <>
      <Routes>
          <Route path="welcome" element={<TopBar locationData={location_data} />} />
          <Route path="guide/*" element={<TopBar locationData={location_data} />} />
          <Route path="engage/*" element={<TopBar locationData={location_data} />} />
          <Route index element={<TopBar locationData={location_data} />} />
      </Routes>
      <Routes>
          <Route path="welcome" element={<Welcome locationData={location_data} />} />
          <Route path="guide/*" element={<GuideWrapper locationData={location_data} guideData={guide} />} />
          <Route path="engage/*" element={<EngageWrapper />} />
          <Route index element={<Welcome locationData={location_data} />} />
      </Routes>
      <Outlet />
      <Routes>
          <Route path="welcome" element={<BottomBar />} />
          <Route path="guide/*" element={<BottomBar />} />
          <Route path="engage/*" element={<BottomBar />} />
          <Route index element={<BottomBar />} />
      </Routes>
    </>
  );
}

export default LocationWrapper;
