import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams, Outlet, } from "react-router-dom";

function About() {
  const user = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();


  useEffect(() => {
    console.log('About');
  }, []);

  let params = useParams();
  return (
    <div className="App">
      <h1>AboutAboutAboutAbout</h1>
      <Outlet />
    </div>
  );
}

export default About;
