import { useSelector, useDispatch } from "react-redux";
import "./App.css";
import { useState } from "react";

import routes from './routes';

function App() {
  return (
      <>
          { routes }
      </>
    );
}

export default App;
