import { useSelector, useDispatch } from "react-redux";
import { useState, useRef, useEffect } from "react";
import { Link, useNavigate, useParams, Outlet, useLocation } from "react-router-dom";
import axios from 'axios';
import { auth } from '../../../../firebase';
import { signInWithEmailAndPassword, signInWithCustomToken } from "firebase/auth";

// import { changeUsersName } from "../../redux/actions/changeUsersName";
import { setLoggedIn } from "../../../../redux/actions/miscActions";
import { getDataAction } from '../../../../redux/actions/getDataActions';

import gtLogo from '../../../../img/gtlogo-color.svg';
import alert from '../../../../img/icons/alert.svg';

import '../style.css';

const gtconfig = require('../../../../gtconfig');

function LoginBookingId() {
  const user = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let location = useLocation();
  let urlParams = useParams();

  const loginFormBookingId = useRef(null);
  const loginFormCheckInDate = useRef(null);

  const [loginForm, setLoginForm] = useState({
    bookingId: '',
    checkInDate: '',
    bookingIdError: '',
    checkInDateError: '',
    loginInProgress: false,
  });

  const [loginInProgress, setLoginInProgress] = useState(false);
  const [loginError, setLoginError] = useState('');

  let params = useParams();


  // const onAuthStateChange = () => {
  //   if (process.env.NODE_ENV !== 'product1ion') {
  //     console.log('Login onAuthStateChange');
  //   }
  //   return auth.onAuthStateChanged((authUser) => {
  //     if (process.env.NODE_ENV !== 'product1ion') {
  //       console.log('Login onAuthStateChanged: ', authUser, ' # ', new Date().getTime()); // comment
  //     }
  //     if (authUser) {
  //       // cookies.set('_lastGtLogin', new Date().getTime(), { path: '/', domain: 'localhost' });
  //       // cookies.set('_lastGtLogin', new Date().getTime(), { path: '/', domain: '.guesttouch.com' });
  //       dispatch(setLoggedIn({
  //         uid: authUser.uid,
  //         loggedIn: true,
  //         loggedOut: false,
  //       }));
  //       navigate(`/${urlParams.locationidentifier}/engage/${authUser.uid}`);
  //     }
  //   });
  // };

  useEffect(() => {
    if (location.pathname.indexOf('/login') === -1) {
      navigate(`/${urlParams.locationidentifier}/engage/login`);
    }
  }, []);

  // useEffect(() => {
  //   // if (cookies.get('_lastGtLogin', { domain: '.guesttouch.com'})) {
  //   //   if (process.env.NODE_ENV !== 'product1ion') {
  //   //     console.log('_lastGtLogin: ', cookies.get('_lastGtLogin', { domain: '.guesttouch.com'}));
  //   //   }
  //     const unsubscribe = onAuthStateChange();
  //     return () => {
  //       unsubscribe();
  //     };
  //   // }
  // }, []);


  function onChange(e) {
    const { name, value } = e.target;
    if (value) {
      switch (name) {
        case 'bookingId':
          let tempError = value ? '' : 'Field is required';
          setLoginForm({
            ...loginForm,
            [name]: value,
            bookingIdError: tempError, // value ? false : true,
          });
          break;
        case 'checkInDate':
          let tValue = value;
          if (process.env.NODE_ENV !== 'product1ion') {
            console.log('onChange checkInDate tValue: ', tValue);
            console.log('onChange checkInDate checkInDate: ', loginForm.checkInDate);
          }
          if (tValue.length > loginForm.checkInDate.length) {
            if (tValue.length === 2) tValue = `${tValue}/`;
            if (tValue.length === 5) tValue = `${tValue}/`;
          }
          // let tArr = tValue.split('/');
          // if (process.env.NODE_ENV !== 'product1ion') {
          //   console.log('onChange checkInDate tArr: ', tArr);
          // }
          // if (tArr.length === 2) {
          //   if (parseInt(tArr[0]) > 12) {
          //     setLoginForm({
          //       ...loginForm,
          //       [name]: tValue,
          //       checkInDateError: value ? '' : 'Field is required',
          //     });
          //     return;
          //   }
          // }
          setLoginForm({
            ...loginForm,
            [name]: tValue,
            checkInDateError: value ? '' : 'Field is required',
          });
          break;
        default:
          setLoginForm({
            ...loginForm,
            [name]: value,
            [`${name}Error`]: false,
          });
      }
    } else {
      setLoginForm({
        ...loginForm,
        [name]: value,
        [`${name}Error`]: true,
      });
    }
    setLoginError('');
  };

  function onSubmit(e) {
    e.preventDefault();

    if (loginForm.loginInProgress) return null;
    // if (props.userprops.get_user_info_in_progress) return null; // nkcheck

    const { bookingId, checkInDate } = loginForm;
    let bookingIdError = bookingId ? '' : 'Field is required';
    let checkInDateError = checkInDate ? '' : 'Field is required';
    setLoginForm({
      ...loginForm,
      bookingIdError,
      checkInDateError,
    });
    if (checkInDateError) {
      if (loginFormCheckInDate) { loginFormCheckInDate.current.focus(); }
    }
    if (bookingIdError) {
      if (loginFormBookingId) { loginFormBookingId.current.focus(); }
    }
    if (!bookingIdError && !checkInDateError) {
      // props.history.push('/home');
      // props.dispatch(setNewUrl('/home'));
      // performLogin(username);
      const params = {
        location__id: urlParams.locationidentifier,
        booking_id: bookingId, // '610ac959f933820008bb4db9',
        check_in_date: checkInDate,
      };
      getLoginToken(params);
    }
  };

  const getLoginToken = (params) => {
    // dispatch(getDataAction({
    //   op: 'get_login_token',
    //   params: {
    //     location__id: 'wZYeknJ4QgyGXj7Jm',
    //     confirmation_code: '610ac959f933820008bb4db9', // urlParams.guestidentifier, // '610ac959f933820008bb4db9', // '61cacbbe27872a00087a1a27',
    //   },
    // }));
    setLoginInProgress(true);
    const url = gtconfig.DATA_BACKEND_URL;
    const opdata = {};
    // opdata.params = params;
    opdata.params = {
      ...params,
      relogin_identifier: window.gtCId,
    };
    const postData = {
      appId: gtconfig.APP_ID,
      // idToken,
      op: 'get_login_token',
      opdata,
    };
    if (process.env.NODE_ENV !== 'product1ion') {
      console.log('getLoginToken postData: ', postData);
    }

    const headers = {
      'Content-type': 'application/json',
      'x-api-key': gtconfig.DATA_BACKEND_URL_API_KEY,
    };
    const axiosData = {
      gtps: postData,
      M6Usp3JlX8gWCInDK3OX: 'Pk7giCRsG75yy8OcVHcN',
    };

    // setGetPreviewInProgress(true);

    axios
      .post(url, axiosData, { headers })
      .then((response) => {
        if (process.env.NODE_ENV !== 'product1ion') {
          console.log('getLoginToken response: ', response.data);
        }
        // setGetPreviewInProgress(false);
        const { status } = response.data;
        const { data } = response.data;
        if (status === 'success' && data) {
          setLoginError('');
          if (process.env.NODE_ENV !== 'product1ion') {
            console.log('getLoginToken data: ', data);
            if (data.token) {
              performLogin(data.token);
            }
          }
          // setSentEmailData(data);
        } else {
          if (process.env.NODE_ENV !== 'product1ion') {
            console.log('getLoginToken error: ', response);
          }
          setLoginError('Error!');
          setLoginInProgress(false);
          // sendEmailAlert({
          //   app: gtconfig.APP_NAME,
          //   subject: `${gtconfig.APP_NAME} - getEmailPreview Error - ${auth.currentUser.uid}`,
          //   message: JSON.stringify({ postData, responseData: response.data }),
          //   error: JSON.stringify(response.data),
          // });
        }
      })
      .catch((err) => {
        // axios error
        if (process.env.NODE_ENV !== 'product1ion') {
          console.log('getLoginToken err--------------------: ', err);
          console.log('getLoginToken err.response --------------------: ', err.response);
        }
        // sendEmailAlert({
        //   app: gtconfig.APP_NAME,
        //   subject: `${gtconfig.APP_NAME} - getEmailPreview Error - axios - ${auth.currentUser.uid}`,
        //   message: JSON.stringify({ url, postData }),
        //   error: JSON.stringify({ err , config: err.config }),
        // });
      })
  }

  const performLogin = (token) => {
    // setLoginForm(prevForm => ({
    //   ...prevForm,
    //   loginInProgress: true,
    // }));
    // setLoginError(false);
    signInWithCustomToken(auth, token)
      .then((authUser) => {
        if (process.env.NODE_ENV !== 'product1ion') {
          console.log('signInWithEmailAndPassword authUser: ', authUser);
        }
        if (authUser && authUser.user) {
          if (process.env.NODE_ENV !== 'product1ion') {
            console.log('Login signInWithEmailAndPassword user: ', authUser.user.uid);
          }
          if (authUser.user.uid.indexOf('_') > -1) {
            let uid = authUser.user.uid.split('_')[1];
            dispatch(setLoggedIn({
              uid,
              loggedIn: true,
              loggedOut: false,
            }));
            navigate(`/${urlParams.locationidentifier}/engage/${uid}`); // 61cacbbe27872a00087a1a27
            // navigate(`/${urlParams.locationidentifier}/engage/61cacbbe27872a00087a1a27`);
          }
        }
        // gtag('event', 'login', {
        //   'method': 'Google'
        // });
        // // gtag('config', 'GA_MEASUREMENT_ID', {
        // //   'user_id': username,
        // // });
        // cookies.set('_lastGtLogin', new Date().getTime(), { path: '/', domain: 'localhost' });
        // cookies.set('_lastGtLogin', new Date().getTime(), { path: '/', domain: '.guesttouch.com' });
        // // props.history.push('/home');
        // let nextRoute = '/home';
        // const queryJson = queryString.parse(props.location.search);
        // const { next, intgkey } = queryJson;
        // if (next) {
        //   nextRoute = next;
        //   if (intgkey) nextRoute = `${nextRoute}?intgkey=${intgkey}`;
        // }
        // if (process.env.NODE_ENV !== 'product1ion') {
        //   console.log('pushing ', nextRoute);
        // }
        // // props.history.push('/categories/guests');
        // props.history.push(nextRoute);
      })
      .catch((error) => {
        if (process.env.NODE_ENV !== 'product1ion') {
          console.log('auth error: ', error);
        }
        // setLoginError(true);
        // setLoginForm(prevForm => ({
        //   ...prevForm,
        //   loginInProgress: false,
        // }));
      });
  };

  // const performLogin = (username) => {
  //   setLoginForm(prevForm => ({
  //     ...prevForm,
  //     loginInProgress: true,
  //   }));
  //   setLoginError(false);
  //   // props.dispatch(setLoginWithCred({
  //   //   loginWithCred: true,
  //   // }));
  //   signInWithEmailAndPassword(auth, username, password)
  //     .then((authUser) => {
  //       if (process.env.NODE_ENV !== 'product1ion') {
  //         console.log('signInWithEmailAndPassword authUser: ', authUser);
  //       }
  //       // gtag('event', 'login', {
  //       //   'method': 'Google'
  //       // });
  //       // // gtag('config', 'GA_MEASUREMENT_ID', {
  //       // //   'user_id': username,
  //       // // });
  //       // cookies.set('_lastGtLogin', new Date().getTime(), { path: '/', domain: 'localhost' });
  //       // cookies.set('_lastGtLogin', new Date().getTime(), { path: '/', domain: '.guesttouch.com' });
  //       // // props.history.push('/home');
  //       // let nextRoute = '/home';
  //       // const queryJson = queryString.parse(props.location.search);
  //       // const { next, intgkey } = queryJson;
  //       // if (next) {
  //       //   nextRoute = next;
  //       //   if (intgkey) nextRoute = `${nextRoute}?intgkey=${intgkey}`;
  //       // }
  //       // if (process.env.NODE_ENV !== 'product1ion') {
  //       //   console.log('pushing ', nextRoute);
  //       // }
  //       // // props.history.push('/categories/guests');
  //       // props.history.push(nextRoute);
  //     })
  //     .catch((error) => {
  //       if (process.env.NODE_ENV !== 'product1ion') {
  //         console.log('auth error: ', error);
  //       }
  //       setLoginError(true);
  //       setLoginForm(prevForm => ({
  //         ...prevForm,
  //         loginInProgress: false,
  //       }));
  //     });
  // };

  const renderField = ({ label, labelHelp, name, placeholder, refVar, error, hlpMsg }) => {
    return (
      <div className="fr">
        <label>{label}
          {
            labelHelp ? <span>({labelHelp})</span> : null
          }
        </label>
        <input
          name={name}
          value={loginForm[name]}
          type={ name === "password" ? "password" : "text" }
          placeholder={placeholder}
          onChange={onChange}
          autoComplete={ name === "password" ? "current-password" : "username" }
          ref={refVar}
        />
        {
          error &&
          <div className="err">Required</div>
        }
        {
          hlpMsg &&
          <div className="hlpMsg"><img src={alert} />{hlpMsg}</div>
        }
      </div>
    );
  };

  const renderLoginForm = () => {
    return (
      <div className="lgOptWrap bookingId">
        <p className="ttl">Guest Sign-In</p>
        <form onSubmit={onSubmit}>
          { renderField({ name: 'bookingId', label: 'Enter your booking Id',
            placeholder: 'Booking/Reservation Id',
            hlpMsg: 'The Booking/Reservation ID is the confirmation number you received when you completed the booking',
            error: loginForm.bookingIdError,
            type: 'input', refVar: loginFormBookingId })
          }

          { renderField({ name: 'checkInDate', label: 'Check-In Date', labelHelp: 'mm/dd/yyyy',
            placeholder: 'mm/dd/yyyy',
            error: loginForm.checkInDateError,
            type: 'input', refVar: loginFormCheckInDate })
          }
          <div className={"btns"}>
            <button type="submit" className={'login' + (loginForm.bookingId && loginForm.checkInDate ? ' active' : '')}>
              Login
              {
                loginInProgress &&
                <div className="loader"><span><i className="fas fa-spinner fa-spin"></i></span></div>
              }
            </button>
          </div>
          {
            loginError &&
            <div className={"err"}>
              {loginError}
            </div>
          }

        </form>
        <div className="otherOpts">
        <Link to={`/${urlParams.locationidentifier}/engage/login`}>Can't sign-in?</Link>
          <Link to={`/${urlParams.locationidentifier}/engage/login`}>Different login method</Link>
        </div>
      </div>
    );
  };

  if (process.env.NODE_ENV !== 'product1ion') {
    console.log('Login urlParams: ', urlParams);
  }

  return (
    <div className="mb eng">
      <div className="enWrp">
          { renderLoginForm() }
      </div>
    </div>
  );
}

export default LoginBookingId;
