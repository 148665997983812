import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams, Outlet, } from "react-router-dom";
import copy from 'copy-to-clipboard';

import { getDataAction, getDataClearAction } from '../../redux/actions/getDataActions';

import AutoComplete from '../AutoComplete';

import './style.css';

const toneOptions = [];
toneOptions.push({ id: 'concise', label: 'Concise'});
toneOptions.push({ id: 'friendly', label: 'Friendly'});
toneOptions.push({ id: 'grok', label: 'Grok'});

function ToneButton(props) {
  const onClick = (e) => {
    props.onToneClick(props.data);
  };
  return (
    <button className={'tbBtn' + (props.selectedTone === props.data.id ? ' active' : '')} onClick={onClick}>{props.data.label}</button>
  );
}

function StarClickable(props) {
  const onMEnter = (e) => { props.onMEnter(props.starValue); }
  const onMLeave = (e) => { props.onMLeave(props.starValue); }
  const onStarClick = (e) => { props.onStarClick(props.starValue); }
  // if (process.env.NODE_ENV !== 'production') {
  //   console.log('StarClickable props: ', props);
  // }
  return (
    <div className={'star' + (props.currentHoverStarValue >= parseInt(props.starValue) ? ' active' : '') + (props.currentSelectedStarValue >= parseInt(props.starValue) ? ' selected' : '')}
      onClick={onStarClick}
      onMouseEnter={onMEnter} onMouseLeave={onMLeave}
    ></div>
  );
}

function Generator() {
  const user = useSelector((state) => state.userReducer);
  const generator = useSelector((state) => state.generatorReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let params = useParams();

  const [currentSelectedStarValue, setCurrentSelectedStarValue] = useState(0);
  const [currentHoverStarValue, setCurrentHoverStarValue] = useState(0);
  const [ratingError, setRatingError] = useState('');

  const [inputText, setInputText] = useState('');
  const [inputError, setInputError] = useState('');

  const [additionalInputText, setAdditionalInputText] = useState('');
  const [additionalInputError, setAdditionalInputError] = useState('');

  const [followUpInputText, setFollowUpInputText] = useState('');

  const [selectedTone, setSelectedTone] = useState('');
  const [someChange, setSomeChange] = useState(false);


  const [selectedLocation, setSelectedLocation] = useState(null);
  const [locError, setLocError] = useState('');

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');

  const [contact, setContact] = useState('');
  const [contactError, setContactError] = useState('');

  const [showCopied, setShowCopied] = useState(false);


  const clearData = () => {
    const params = {};
    dispatch(getDataClearAction({
      op: 'get_response',
      params,
    }));
    setInputText('');
    setAdditionalInputText('');
    setFollowUpInputText('');
    setCurrentSelectedStarValue(0);
    dispatch(getDataClearAction({
      op: 'submit_location',
      params,
    }));
    setShowCopied(false);
    setSomeChange(false);
  };

  const getResponseData = (input_text) => {
    const params = {
      input_text,
      src: 'XJd5GQVSVi1eM4k6G4yJ',
    };
    if (additionalInputText) params.additional_input_text = additionalInputText;
    if (followUpInputText) params.follow_up_text = followUpInputText;
    if (generator.conv_messages && generator.conv_messages.length) {
      params.conv_messages = [ ...generator.conv_messages ];
    }
    dispatch(getDataAction({
      op: 'get_response_internal',
      params,
    }));
  };

  useEffect(() => {
    clearData();
  }, []);

  const onChange = (e) => {
      setInputText(e.target.value);
      setInputError(e.target.value ? false : true);
      setSomeChange(true);
  };
  const onAdditionalInputChange = (e) => {
      setAdditionalInputText(e.target.value);
      // setAdditionalInputError(e.target.value ? false : true);
      setSomeChange(true);
  };
  const onFollowUpInputChange = (e) => {
      setFollowUpInputText(e.target.value);
      setSomeChange(true);
  };

  const onStarClickableClick = (starValue) => {
    setCurrentSelectedStarValue(starValue);
    setRatingError(false);
    // props.onStarClick(props.data._id, starValue);
    setSomeChange(true);
  };
  const onStarClickableMEnter = (starValue) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onStarClickableMEnter: ', starValue);
    }
    setCurrentHoverStarValue(parseInt(starValue));
  };
  const onStarClickableMLeave = (starValue) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onStarClickableMLeave: ', starValue);
    }
    setCurrentHoverStarValue(0);
  };

  const onToneClick = (data) => {
    setSelectedTone(data.id);
    setSomeChange(true);
  };

  const onSubmit = (e) => {
    let hasError = false;
    if (!currentSelectedStarValue) {
      setRatingError(true); hasError = true;
    } else { setRatingError(false); }
    if (!inputText) {
      setInputError(true); hasError = true;
    } else { setInputError(false); }
    if (hasError) return;

    getResponseData(inputText);
    setShowCopied(false);
  };

  const onReset = (e) => {
    clearData();
  };

  const onResponseCopyClick = (e) => {
    copy(generator.generatedResponse);
    setShowCopied(true);
  };


  const onLocationSelect = (data) => {
    setSelectedLocation(data);
    setLocError(data ? false : true);
  }
  const onEmailChange = (e) => {
    setEmail(e.target.value);
    setEmailError(e.target.value ? false : true);
  };
  const onContactChange = (e) => {
    setContact(e.target.value);
    setContactError(e.target.value ? false : true);
  };
  const onLocSubmit = (e) => {
    let hasError = false;
    if (!selectedLocation) {
      setLocError(true); hasError = true;
    } else { setLocError(false); }
    if (!email) {
      setEmailError(true); hasError = true;
    } else { setEmailError(false); }
    if (!contact) {
      setContactError(true); hasError = true;
    } else { setContactError(false); }
    if (hasError) return;

    if (process.env.NODE_ENV !== 'production') {
      console.log('onLocSubmit selectedLocation: ', selectedLocation);
    }

    const params = {
      loc: selectedLocation,
    };
    dispatch(getDataAction({
      op: 'submit_location',
      params,
    }));

  };

  const renderConnectLocation = () => {
    return (
      <div className="cnLocWrp">
        <div className="cnLoctBx">
          <div className="tp">
            Experience seamless responses across channels
          </div>
          <div className="frm">
            <div className="iptBx">
              <div className="gLbl">Hotel</div>
              <div className="tp">
                <AutoComplete onLocationSelect={onLocationSelect} />
              </div>
              {
                locError &&
                <div className="gErr">
                  Required
                </div>
              }
            </div>

            <div className="iptBx">
              <div className="gLbl">Email</div>
              <div className="tp">
                <input type="text" value={email} onChange={onEmailChange} placeholder="Email" />
              </div>
              {
                emailError &&
                <div className="gErr">
                  Required
                </div>
              }
            </div>
            <div className="iptBx">
              <div className="gLbl">Contact</div>
              <div className="tp">
                <input type="text" value={contact} onChange={onContactChange} placeholder="Contact" />
              </div>
              {
                contactError &&
                <div className="gErr">
                  Required
                </div>
              }
            </div>
            <div className="btnBx">
              <button className="gBtn sbmBtn" onClick={onLocSubmit}>
                Submit
                {
                  generator.submitLocationInProgress &&
                  <i className="fa fa-spinner fa-spin" />
                }
              </button>
            </div>
            {
              generator.submitLocationStatus && generator.submitLocationStatus.success &&
              <div className="stsBx">
                Thanks for the submission, our team will reach out shortly.
              </div>
            }
          </div>
        </div>
      </div>
    )
  };

  const renderBox = () => {
    return (
      <div className="bxWrp">
        <div className="gnrtBx">
          <div className="rtngBx">
            <div className="gLbl">Rating</div>
            <div className="tp">
              <div className="stars">
                {
                  Array.from(Array(5).keys()).map((item) => {
                    return (
                      <StarClickable starValue={item+1}
                        currentSelectedStarValue={currentSelectedStarValue}
                        currentHoverStarValue={currentHoverStarValue}
                        onStarClick={onStarClickableClick}
                        onMEnter={onStarClickableMEnter} onMLeave={onStarClickableMLeave}
                        key={`str_${item+1}`}
                      />
                    );
                  })
                }
              </div>
            </div>
            {
              ratingError &&
              <div className="gErr">
                Required
              </div>
            }
          </div>
          <div className="iptBx">
            <div className="gLbl">Review</div>
            <div className="tp">
              <textarea value={inputText} onChange={onChange} placeholder="Review goes here..." />
            </div>
            {
              inputError &&
              <div className="gErr">
                Required
              </div>
            }
          </div>
          <div className="iptBx adtnl">
            <div className="gLbl">Additional instructions (optional)</div>
            <div className="tp">
              <textarea value={additionalInputText} onChange={onAdditionalInputChange} placeholder="Review is for ABC hotel, etc ..." />
            </div>
            {
              additionalInputError &&
              <div className="gErr">
                Required
              </div>
            }
          </div>
          <div className="toneBx">
            {
              toneOptions.map((item) => {
                return <ToneButton key={`tb_${item.id}`} data={item} selectedTone={selectedTone} onToneClick={onToneClick} />
              })
            }
          </div>
          {
            generator.generatedCount > 0 &&
            <div className="iptBx folUp">
              <div className="gLbl">Follow up instructions</div>
              <div className="tp">
                <textarea value={followUpInputText} onChange={onFollowUpInputChange} placeholder="Do no promise any action, etc..." />
              </div>
            </div>
          }

          <div className="btnBx">
            <button className="gBtn sbmBtn" onClick={onSubmit}>
              { generator.generatedCount ? 'Regenerate Response' : 'Generate Response' }
              {
                generator.getResponseInProgress &&
                <i className="fa fa-spinner fa-spin" />
              }
            </button>
            {
              someChange &&
              <button className="gBtn cancel" onClick={onReset}>
                Reset
              </button>
            }
          </div>
        </div>
        {
          generator.generatedResponse &&
          <div className="rspnBx">
            {
              generator.getResponseInProgress &&
              <div className="gPnlLdng">
                <i className="fa fa-spinner fa-spin" />
              </div>
            }
            <div className="lbl">Generated Response</div>
            {
              generator.generatedResponse &&
              <div className="val">{generator.generatedResponse}</div>
            }
            <div className="cpBx">
              <span onClick={onResponseCopyClick}><i class="fa-regular fa-copy"></i> { showCopied ? 'Copied' : 'Copy' }</span>
            </div>
          </div>
        }
      </div>
    );
  };

  if (process.env.NODE_ENV !== 'production') {
    console.log('Generator currentHoverStarValue: ', currentHoverStarValue);
    console.log('Generator currentSelectedStarValue: ', currentSelectedStarValue);
  }

  return (
    <div className="App">
      <h1>Response Generator</h1>

      { renderBox() }

      { renderConnectLocation() }
      <Outlet />
    </div>
  );
}

export default Generator;
