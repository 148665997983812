import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams, Outlet, useLocation } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import homeIcon from '../../img/icons/home.svg';
import homeActiveIcon from '../../img/icons/home-active.svg';
import guideIcon from '../../img/icons/guide.svg';
import guideActiveIcon from '../../img/icons/guide-active.svg';
import chatIcon from '../../img/icons/chat.svg';
import chatActiveIcon from '../../img/icons/chat-active.svg';

import './style.css';

function Tab(props) {
  let active = props.location.pathname.indexOf(props.link) > -1 ? true : false;
  if (
    props.link === `/${props.locationidentifier}/welcome` &&
    (
      props.location.pathname === `/${props.locationidentifier}` ||
      props.location.pathname === `/${props.locationidentifier}/`
    )
  ) {
    active = true;
  }
  return (
    <Link to={props.link} className={(active ? 'active' : '')}>
      <img src={active ? props.iconActive : props.icon} />
      {props.label}
      {
        props.unreadCount ? <span className="uc">{props.unreadCount}</span> : null
      }
    </Link>
  )
}

function BottomBar() {
  const userState = useSelector((state) => state.userReducer);
  const engageState = useSelector((state) => state.engageReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let location = useLocation();
  let urlParams = useParams();

  useEffect(() => {
  }, []);

  const BarContents = (props) => {
    return (
      <div className="bb">
        <div className="inr">
          <Tab label="Welcome" link={`/${urlParams.locationidentifier}/welcome`} location={location}
            icon={homeIcon}
            iconActive={homeActiveIcon}
            locationidentifier={urlParams.locationidentifier}
          />
          <Tab label="Guide" link={`/${urlParams.locationidentifier}/guide`} location={location}
            icon={guideIcon}
            iconActive={guideActiveIcon}
            locationidentifier={urlParams.locationidentifier}
          />
          <Tab label="Engage" link={`/${urlParams.locationidentifier}/engage`} location={location}
            icon={chatIcon}
            iconActive={chatActiveIcon}
            unreadCount={unreadCount}
            locationidentifier={urlParams.locationidentifier}
          />
        </div>
      </div>
    );
  };

  if (process.env.NODE_ENV !== 'production') {
    console.log('location pathaname: ', location.pathname);
    console.log('BottomBar urlParams: ', urlParams);
  }

  let activeTab = location.pathname.indexOf('')

  const unreadCount = engageState.guest_data.unreadCount || 0;

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route path="room-info/*" element={<Outlet />}>
            <Route index element={null} />
        </Route>
        <Route path="hotel-info/*" element={<Outlet />}>
            <Route path=":pageid/*" element={null} />
            <Route index element={null} />
        </Route>
        <Route path=":guestidentifier/*" element={<BarContents />} />
        <Route path="login/*" element={<Outlet />}>
          <Route path="booking-id/*" element={<BarContents />} />
          <Route index element={<BarContents />} />
        </Route>
        <Route index element={<BarContents />} />
      </Route>
    </Routes>
  );

  return (
    <div className="bb">
      <div className="inr">
        <Tab label="Welcome" link={`/${urlParams.locationidentifier}/welcome`} location={location}
          icon={homeIcon}
          iconActive={homeActiveIcon}
          locationidentifier={urlParams.locationidentifier}
        />
        <Tab label="Guide" link={`/${urlParams.locationidentifier}/guide`} location={location}
          icon={guideIcon}
          iconActive={guideActiveIcon}
          locationidentifier={urlParams.locationidentifier}
        />
        <Tab label="Engage" link={`/${urlParams.locationidentifier}/engage`} location={location}
          icon={chatIcon}
          iconActive={chatActiveIcon}
          unreadCount={unreadCount}
          locationidentifier={urlParams.locationidentifier}
        />
      </div>
    </div>
  );
}

export default BottomBar;
