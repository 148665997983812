import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams, Outlet, useLocation } from "react-router-dom";
import DOMPurify from 'dompurify';

import { auth } from '../../../firebase';
import { getDataAction, getDataClearAction } from '../../../redux/actions/getDataActions';
import { getDataAuthAction } from '../../../redux/actions/getDataAuthActions';

import backIcon from '../../../img/icons/back.svg';
import nextIcon from '../../../img/icons/next.svg';

import { formatDate } from '../../../helpers';

import '../style.css';

import GuideTopBar from '../GuideTopBar';

const SectionBox = (props) => {
  return (
    <div className={'dgPgSecBx ' + (props.type)}>
      {
        props.type === 'text'
        ? <>
            {
              props.html &&
              <div className="inr" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.html, { ADD_ATTR: ['target'] }) }} />
            }
          </>
        : null
      }
      {
        props.type === 'image'
        ? <>
            {
              props.url &&
              <img src={props.url} />
            }
          </>
        : null
      }
    </div>
  );
};


function GuideRoomInfo(props) {
  const userState = useSelector((state) => state.userReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let location = useLocation();
  let urlParams = useParams();


  const getData = () => {
    if (process.env.NODE_ENV !== 'production') {
      console.log(`getData`);
    }
    if (userState.user && userState.user.uid && userState.user.loggedIn && auth.currentUser) {
      dispatch(getDataAuthAction({
        op: 'get_guide_room_info',
        params: {
          location__id: urlParams.locationidentifier,
          guest_id: userState.user.uid,
        },
      }));
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const renderStayInfo = (guestData) => {
    if (!guestData) return null;
    return (
      <div className="styBx">
        <div className="tp">
          <div className="etry">
            <span className="lbl">Room Key</span>
            <span className="val">{guestData.keycode || '-'}</span>
          </div>
        </div>
        <div className="bt">
          <div className="etry">
            <span className="lbl">Check-In Date</span>
            <span className="val">{formatDate(guestData.check_in_date)}</span>
          </div>
          <div className="etry">
            <span className="lbl">Check-Out Date</span>
            <span className="val">{formatDate(guestData.check_out_date)}</span>
          </div>
          {
            guestData.room_number && !guestData.room_type &&
            <div className="etry">
              <span className="lbl">Room Number</span>
              <span className="val">{guestData.room_number}</span>
            </div>
          }
          {
            !guestData.room_number && guestData.room_type &&
            <div className="etry">
              <span className="lbl">Room Type</span>
              <span className="val">{guestData.room_type}</span>
            </div>
          }
        </div>
      </div>
    )
  }


  const { guideData } = props;
  if (!guideData) return null;
  const { room_info } = guideData;

  // if (room_info && room_info.getInProgress) {
  //   return (
  //     <div className="gPnlLdng">
  //       <i className="fa fa-spinner fa-spin" />
  //     </div>
  //   );
  // }

  let backUrl = `/${urlParams.locationidentifier}/guide/`;

  return (
    <>
      {
        room_info && room_info.getInProgress &&
        <div className="gPnlLdng">
          <i className="fa fa-spinner fa-spin" />
        </div>
      }
      <div className="mb gde htlInf rmInf">
        <GuideTopBar
          label={"My Room"}
          backUrl={backUrl}
        />
        <div className="bdCnt">
          { renderStayInfo(room_info) }
          <div className="rmPg">
            {
              room_info && room_info.page && room_info.page.sections && room_info.page.sections.length
              ?
                room_info.page.sections.map((item) => {
                  return (
                    <SectionBox {...item}
                    />
                  )
                })
              : null
            }
            {
              room_info && room_info.page && room_info.page.sections && room_info.page.sections.length
              ?
                room_info.page.sections.map((item) => {
                  return (
                    <SectionBox {...item}
                    />
                  )
                })
              : null
            }
          </div>
          {/*
            hotel_info && hotel_info.pages && hotel_info.pages.list && hotel_info.pages.keyValue
            ? hotel_info.pages.list.map((pageId) => {
              if (!hotel_info.pages.keyValue[pageId]) return null;
              return (
                <PageRow
                  id={pageId}
                  data={hotel_info.pages.keyValue[pageId]}
                  locationidentifier={urlParams.locationidentifier}
                />
              )
              })
            : null
          */}
        </div>
      </div>
    </>
  );
}

export default GuideRoomInfo;
