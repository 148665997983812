import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams, Outlet, useLocation } from "react-router-dom";

const sections = [];
sections.push({ 'id': 'room-info', 'enabled' : true });
sections.push({ 'id': 'hotel-info', 'enabled' : true });
sections.push({ 'id': 'things-to-do', 'enabled' : true });
sections.push({ 'id': 'places-to-eat', 'enabled' : true });

function SectionBox(props) {
  return (
    <Link to={`/${props.locationidentifier}/guide/${props.id}`}>
      <div className="sectBx">
        { props.id }
      </div>
    </Link>
  )
}

function Guide(props) {
  const userState = useSelector((state) => state.userReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let location = useLocation();
  let urlParams = useParams();

  useEffect(() => {
  }, []);

  const { locationData } = props;

  return (
    <div className="mb gde">
      <h1>Guide</h1>
      {
        sections.map((item) => {
          return (
            <SectionBox
              id={item.id}
              locationidentifier={urlParams.locationidentifier}
            />
          )
        })
      }
    </div>
  );
}

export default Guide;
