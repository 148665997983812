import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams, Outlet, useLocation } from "react-router-dom";

// import { getDataAction, getDataClearAction } from '../../../redux/actions/getDataActions';

import backIcon from '../../../img/icons/back.svg';

import '../style.css';

function GuideTopBar(props) {

  return (
    <div className="tpBr">
      <div className="lt">
        <Link to={props.backUrl}>
          <img src={backIcon} />
        </Link>
      </div>
      <div className="rt">
        <h1>{props.label || '-'}</h1>
      </div>
    </div>
  );
}

export default GuideTopBar;
