import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams, Outlet, useLocation } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import { initGuideAction } from '../../../redux/actions/initActions';
import { getDataAction } from '../../../redux/actions/getDataActions';

import GuideHome from '../GuideHome';
import GuideRoomInfo from '../GuideRoomInfo';
import GuideHotelInfo from '../GuideHotelInfo';
import GuideThingsToDo from '../GuideThingsToDo';
import GuidePlacesToEat from '../GuidePlacesToEat';
import InfoPage from '../../Misc/InfoPage';


function GuideWrapper(props) {
  const userState = useSelector((state) => state.userReducer);
  const engageState = useSelector((state) => state.engageReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();
  let location = useLocation();

  useEffect(() => {
    dispatch(initGuideAction({
      params: {
        location__id: urlParams.locationidentifier,
      },
    }));
  }, []);

  if (process.env.NODE_ENV !== 'production') {
    console.log('GuideWrapper urlParams : ', urlParams);
  }

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route path="room-info/*" element={<Outlet />}>
          <Route index element={<GuideRoomInfo {...props} />} />
        </Route>
        <Route path="hotel-info/*" element={<Outlet />}>
          <Route path=":pageid/*" element={<InfoPage isHotelInfo={true} {...props} />} />
          <Route index element={<GuideHotelInfo {...props} />} />
        </Route>
        <Route path="things-to-do/*" element={<Outlet />}>
          <Route index element={<GuideThingsToDo {...props} />} />
        </Route>
        <Route path="places-to-eat/*" element={<Outlet />}>
          <Route index element={<GuidePlacesToEat {...props} />} />
        </Route>
        <Route index element={<GuideHome />} />
      </Route>
    </Routes>
  );

  // return (
  //   <div className="mb eng">
  //     <div className="enWrp">
  //       <ConversationBox />
  //       <SendBox />
  //     </div>
  //   </div>
  // );
}

export default GuideWrapper;
