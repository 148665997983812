import * as guideHelper from './guideHelper';

const initialState = {};

const engageReducer = (state = initialState, action) => {
  switch (action.type) {
    // case 'USER_LOGOUT': {
    //   return initialState;
    // }
    case 'GET_RESPONSE_CLEAR': {
      const { params: { input_text } } = action.payload;
      return {
        ...state,
        generatedResponse: '',
        generatedCount: 0,
        conv_messages: [],
        getResponseInProgress: false,
        getResponseStatus: {},
      };
    }
    case 'GET_RESPONSE': {
      const { params: { input_text } } = action.payload;
      if (!input_text) return state;
      return {
        ...state,
        getResponseInProgress: true,
        getResponseStatus: {},
      };
    }
    case 'GET_RESPONSE_SUCCESS': {
      const { data, params: { input_text } } = action.payload;
      if (!input_text) return state;
      const { generated_response, messages } = data;
      return {
        ...state,
        generatedCount: state.generatedCount ? state.generatedCount+1 : 1,
        generatedResponse: generated_response,
        conv_messages: messages,
        getResponseInProgress: false,
        getResponseStatus: {
          success: true,
        },
      };
    }
    case 'GET_RESPONSE_ERROR': {
      const { error, errorMessage, params } = action.payload;
      if (!params) return state;
      const { input_text } = params;
      if (!input_text) return state;
      return {
        ...state,
        getResponseInProgress: false,
        getResponseStatus: {
          error: true,
        },
      };
    }


    case 'SUBMIT_LOCATION_CLEAR': {
      const { params: { input_text } } = action.payload;
      return {
        ...state,
        submitLocationInProgress: false,
        submitLocationStatus: {},
      };
    }
    case 'SUBMIT_LOCATION': {
      const { params: { loc } } = action.payload;
      if (!loc) return state;
      return {
        ...state,
        submitLocationInProgress: true,
        submitLocationStatus: {},
      };
    }
    case 'SUBMIT_LOCATION_SUCCESS': {
      const { data, params: { loc } } = action.payload;
      if (!loc) return state;
      const { status } = data;
      return {
        ...state,
        submitLocationInProgress: false,
        submitLocationStatus: {
          success: true,
        },
      };
    }
    case 'SUBMIT_LOCATION_ERROR': {
      const { error, errorMessage, params } = action.payload;
      if (!params) return state;
      const { loc } = params;
      if (!loc) return state;
      return {
        ...state,
        submitLocationInProgress: false,
        submitLocationStatus: {
          error: true,
        },
      };
    }

    default:
  }
  return state;
}
export default engageReducer;
