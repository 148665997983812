import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams, Outlet, useLocation } from "react-router-dom";

import arrowIcon from '../../../img/icons/arrow.svg';
import myRoomIcon from '../../../img/icons/my-room.svg';
import hotelInfoIcon from '../../../img/icons/hotel-info.svg';
import toDoIcon from '../../../img/icons/to-do.svg';
import placesToEatIcon from '../../../img/icons/places-to-eat.svg';

import './style.css';

const sections = [];
sections.push({ 'id': 'room-info', 'label': 'My Room', 'desc': 'Find out details about your room; TV, AC, Key and more', icon: myRoomIcon, 'enabled' : true });
sections.push({ 'id': 'hotel-info', 'label': 'Hotel Information', 'desc': 'Find out details about your room; TV, AC, Key and more', icon: hotelInfoIcon, 'enabled' : true });
sections.push({ 'id': 'things-to-do', 'label': 'Things to Do', 'desc': 'Find out details about your room; TV, AC, Key and more', icon: toDoIcon, 'enabled' : true });
sections.push({ 'id': 'places-to-eat', 'label': 'Restaurants', 'desc': 'Find out details about your room; TV, AC, Key and more', icon: placesToEatIcon, 'enabled' : true });

function SectionBox(props) {
  return (
    <Link to={`/${props.locationidentifier}/guide/${props.id}`}>
      <div className="sectBx">
        <div className="lt">
          <img src={props.icon} />
        </div>
        <div className="rt">
          <div className="lblRw">
            <div className="lt">
              { props.label }
            </div>
            <div className="rt">
              <img src={arrowIcon} />
            </div>
          </div>
          <div className="descRw">
            { props.desc }
          </div>
        </div>
      </div>
    </Link>
  )
}

function GuideHome(props) {
  const userState = useSelector((state) => state.userReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let location = useLocation();
  let urlParams = useParams();

  useEffect(() => {
  }, []);

  const { locationData } = props;

  return (
    <div className="mb gde hmPg">
      <div className="bdCnt">
        {
          sections.map((item) => {
            return (
              <SectionBox
                key={`sb_${item.id}`}
                { ...item }
                locationidentifier={urlParams.locationidentifier}
              />
            )
          })
        }
      </div>
    </div>
  );
}

export default GuideHome;
