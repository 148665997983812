import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams, Outlet, } from "react-router-dom";

import useDebounce from '../../Misc/DebounceHook';

import { editDataAction, editDataClearAction } from '../../../redux/actions/editDataActions';

import { formatMessageDate } from '../../../helpers';

import './style.css';

let tData = [];
tData.push({ _id: 1, type: 1, msg: 'sent msg 1' });
tData.push({ _id: 2, type: 2, msg: 'rec msg 1' });
tData.push({ _id: 3, type: 1, msg: 'sent msg 2' });
tData.push({ _id: 4, type: 2, msg: 'rec msg 2' });
tData.push({ _id: 5, type: 1, msg: 'sent msg 3' });
tData.push({ _id: 6, type: 2, msg: 'rec msg 3' });
tData.push({ _id: 7, type: 1, msg: 'sent msg 4' });
tData.push({ _id: 8, type: 2, msg: 'rec msg 4' });

function MessageSent(props) {
  return (
    <div className="msgEtry snt" id={`m_${props.data._id}`}>
      <div className="msgBox">
        <div className="msg">
          {props.data.msg}
        </div>
        <div className="msgSts">
          {formatMessageDate(props.data.date_created)}
          { props.sendMessageInProgress && <i className="fa fa-spinner fa-spin" /> }
        </div>
      </div>
    </div>
  )
}
function MessageReceived(props) {
  return (
    <div className="msgEtry rec" id={`m_${props.data._id}`}>
      <div className="msgBox">
        <div className="msg">
          {props.data.msg}
        </div>
        <div className="msgSts">
          {formatMessageDate(props.data.date_created)}
        </div>
      </div>
    </div>
  )
}

function ConversationBox(props) {
  const userState = useSelector((state) => state.userReducer);
  const engageState = useSelector((state) => state.engageReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();

  const [lastScrollTop, setLastScrollTop] = useState(0);

  const debouncedLastScrollTop = useDebounce(lastScrollTop, 500);

  const convBxRef = useRef();


  useEffect(() => {
    const convBxRefElem = convBxRef.current;
    if (convBxRefElem) {
      convBxRefElem.addEventListener('scroll', handleScroll);
      if (process.env.NODE_ENV !== 'production') {
        console.log('handleScroll added');
      }
      return () => {
          convBxRefElem.removeEventListener('scroll', handleScroll);
          if (process.env.NODE_ENV !== 'production') {
            console.log('handleScroll removed');
          }
      };
    }
  },[]);

  // useEffect(() => {
  //   if (process.env.NODE_ENV !== 'production') {
  //     console.log('scrollTop changed: ', lastScrollTop);
  //   }
  //   if (engageState.guest_data && engageState.guest_data.unreadCount) {
  //     if (process.env.NODE_ENV !== 'production') {
  //       console.log('unreadCount : ', engageState.guest_data.unreadCount);
  //     }
  //     if (engageState.guest_data && !engageState.guest_data.markMessageAsReadInProgress) {
  //       markMessageAsRead();
  //     }
  //   }
  // }, [lastScrollTop]);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('scrollTop changed: ', lastScrollTop);
    }
    if (engageState.guest_data && engageState.guest_data.unreadCount) {
      if (process.env.NODE_ENV !== 'production') {
        console.log('unreadCount : ', engageState.guest_data.unreadCount);
      }
      if (engageState.guest_data && !engageState.guest_data.markMessageAsReadInProgress) {
        markMessageAsRead();
      }
    }
  }, [debouncedLastScrollTop]);

  const markMessageAsRead = () => {
    const foundUnread = engageState.guest_data && engageState.guest_data.data &&
      engageState.guest_data.data.messages ? engageState.guest_data.data.messages.filter(el => !el.webapp_read) : false;
    if (process.env.NODE_ENV !== 'production') {
      console.log('foundUnread : ', foundUnread);
    }
    if (foundUnread) {
      dispatch(editDataAction({
        op: 'mark_message_as_read',
        params: {
          location__id: urlParams.locationidentifier, // 'wZYeknJ4QgyGXj7Jm#',
          guest_id: urlParams.guestidentifier, // '610ac959f933820008bb4db9', // '61cacbbe27872a00087a1a27',
          all: true,
        },
      }));
    }
  };

  const handleScroll = (event) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('handleScroll window.scrollY: ', window.scrollY);
    }
    if (convBxRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = convBxRef.current;
      const isNearBottom = scrollTop + clientHeight >= scrollHeight;
      // if (process.env.NODE_ENV !== 'production') {
      //   console.log('handleScroll scrollTop: ', scrollTop);
      // }
      setLastScrollTop(scrollTop);
    }
  }


  let messageList = engageState.guest_data.data && engageState.guest_data.data.messages
    ? [ ...engageState.guest_data.data.messages ] : [];
  return (
    <div className="convBx" ref={convBxRef}>
      {
        messageList.map((item, index) => {
          if (item.type === 1) {
            return <MessageSent data={item} key={`ms_${item._id}`} sendMessageInProgress={index === 0 && engageState.guest_data.sendMessageInProgress} />
          }
          if (item.type === 2) {
            return <MessageReceived data={item} key={`mr_${item._id}`} />
          }
          return null;
        })
      }
    </div>
  );
}

export default ConversationBox;
