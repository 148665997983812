import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import Generator from '../components/Generator';
import About from '../components/About';
import NotAllowed from '../components/NotAllowed';
import Wrapper from '../components/Wrapper';

const routes = (
  <BrowserRouter>
    <Routes>
      <Route path="/generator" element={<Generator />} />
      <Route path="/about" element={<About />} />
      <Route path="/not-allowed" element={<NotAllowed />} />
      <Route path="*" element={<Wrapper />} />
      {/*<Route path=":locationidentifier/*" element={<Wrapper />} />*/}
    </Routes>
</BrowserRouter>
);

export default routes;
