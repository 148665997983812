import axios from 'axios';

import { auth } from '../../firebase';

const gtconfig = require('../../gtconfig');

const opMap = {
  'get_guest': 'GET_GUEST',
  'get_guide_room_info': 'GET_GUIDE_ROOM_INFO',
};

/* ********************** Start - Get Data *********************************** */

export function getDataAuthClearAction(payload) {
  return function somename(dispatch, getState) {
    dispatch({
      type: `${opMap[payload.op]}_CLEAR`,
      payload,
    });
  };
}

export function getDataAuthAction(payload) {
  return function somename(dispatch, getState) {

    if (process.env.NODE_ENV !== 'production') {
      console.log(`getDataAuthAction ${payload.op} payload: ${JSON.stringify(payload)}`);
    }

    dispatch({
      type: opMap[payload.op],
      payload,
    });

    const { op, params, clear } = payload;

    if (clear) {
      dispatch({
        type: `${opMap[payload.op]}_CLEAR`,
        payload,
      });
    }

    auth.currentUser
      .getIdToken(false)
      .then((idToken) => {
        const url = gtconfig.DATA_BACKEND_URL;
        if (process.env.NODE_ENV !== 'production') {
          console.log(`getDataAuthAction ${payload.op} url: `, url);
        }
        const opdata = {};
        opdata.params = {
          ...params,
          relogin_identifier: window.gtCId,
        };
        const postData = {
          appId: gtconfig.APP_ID,
          idToken,
          op, // 'get_guest_list',
          opdata,
        };
        if (payload.idToken) postData.idToken = payload.idToken;

        if (process.env.NODE_ENV !== 'production') {
          console.log(`getDataAuthAction ${payload.op} postData: `, postData);
        }

        const headers = {
          'Content-type': 'application/json',
          'x-api-key': gtconfig.DATA_BACKEND_URL_API_KEY,
        };
        // const axiosData = {
        //   gtp: CryptoJS.AES.encrypt(JSON.stringify(postData), gtconfig.DATA_BACKEND_URL_GT_BASIC_KEY).toString(),
        // };
        const axiosData = {
          gtps: postData,
          M6Usp3JlX8gWCInDK3OX: 'Pk7giCRsG75yy8OcVHcN',
        };

        axios
          .post(url, axiosData, { headers })
          .then((response) => {
            if (process.env.NODE_ENV !== 'production') {
              console.log(`getDataAuthAction ${payload.op} response: `, response.data);
            }
            const { status } = response.data;
            const { data } = response.data;
            // if (status === 'success' && data) {
            //   dispatch({
            //     type: 'SET_VERSION',
            //     payload: { data, params },
            //   });
            // }
            if (status === 'success' && data) {
              const id = (new Date()).getTime();
              let msg = payload.alert_msg || 'Updated successfully';
              if (payload.show_alert) {
                dispatch({
                  type: 'RECEIVED_NEW_SNACK',
                  payload: {
                    snack: {
                      id,
                      msg,
                    },
                  },
                });
                setTimeout(() => {
                  dispatch({ type: 'REMOVE_SNACK', payload: { id } });
                }, 2000);
              }
              dispatch({
                type: `${opMap[payload.op]}_SUCCESS`,
                payload: {
                  data,
                  params,
                },
              });
            } else {
              if (process.env.NODE_ENV !== 'production') {
                console.log(`getDataAuthAction ${payload.op} error: `, response);
              }
              // sendEmailAlert({
              //   app: gtconfig.APP_NAME,
              //   subject: `${gtconfig.APP_NAME} - getGuestListAction Error - ${auth.currentUser.uid}`,
              //   message: JSON.stringify({ postData, responseData: response.data }),
              //   error: JSON.stringify(response.data),
              //   store: JSON.stringify(getState()),
              // });
              dispatch({
                type: `${opMap[payload.op]}_ERROR`,
                payload: {
                  data,
                  params,
                },
              });
              if (payload.show_error_alert) {
                const id = (new Date()).getTime();
                dispatch({
                  type: 'RECEIVED_NEW_SNACK',
                  payload: {
                    snack: {
                      id,
                      msg: payload.error_alert_msg || 'Error in updating!',
                    },
                  },
                });
              }
            }
          })
          .catch((err) => {
            // axios error
            if (process.env.NODE_ENV !== 'production') {
              console.log(`getDataAuthAction ${payload.op} err--------------------: `, err);
              console.log(`getDataAuthAction ${payload.op} err.response--------------------: `, err.response);
            }
            // sendEmailAlert({
            //   app: gtconfig.APP_NAME,
            //   subject: `${gtconfig.APP_NAME} - getGuestListAction Error - axios - ${auth.currentUser.uid}`,
            //   message: JSON.stringify({ url, postData }),
            //   error: JSON.stringify({ err , config: err.config }),
            //   store: JSON.stringify(getState()),
            // });
            dispatch({
              type: `${opMap[payload.op]}_ERROR`,
              payload: {
                params,
                error: err,
                errorMessage: err.toString(),
              },
            });
          })
          .catch((err) => {
          // firebase error
            if (process.env.NODE_ENV !== 'production') {
              console.log(`getDataAuthAction ${payload.op} firebase err--------------------: ${err}`);
            }
            // sendEmailAlert({
            //   app: gtconfig.APP_NAME,
            //   subject: `${gtconfig.APP_NAME} - getGuestListAction Error - firebase`,
            //   message: 'Firebase error',
            //   error: err.toString(),
            //   store: JSON.stringify(getState()),
            // });
            dispatch({
              type: `${opMap[payload.op]}_ERROR`,
              payload: {
                ...payload,
                errorMessage: err.toString(),
              },
            });
          });
      });
  };
}

/* ***************************  End - Get Data  ****************************** */
