import { combineReducers } from "redux";
import windowReducer from "./windowReducer";
import appReducer from "./appReducer";
import generatorReducer from "./generatorReducer";

const allReducers = combineReducers({
  windowReducer: windowReducer,
  appReducer: appReducer,
  generatorReducer: generatorReducer,
});

export default allReducers;
