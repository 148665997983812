import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// Initialize Firebase
// const config = {
//   apiKey: 'AIzaSyA2m5BPiSUfT4XPLIApN_MCEp3CrZUyMD8',
//   authDomain: 'gtauth-41a19.firebaseapp.com',
//   databaseURL: 'https://gtauth-41a19.firebaseio.com',
//   projectId: 'gtauth-41a19',
//   storageBucket: 'gtauth-41a19.appspot.com',
//   messagingSenderId: '828817167840',
// };
const firebaseConfig = {
  apiKey: "AIzaSyAsJvg7YPzHsYuDOjc8VjUPPQon7UYC06w",
  authDomain: "gtengage-d5674.firebaseapp.com",
  projectId: "gtengage-d5674",
  storageBucket: "gtengage-d5674.appspot.com",
  messagingSenderId: "46944710314",
  appId: "1:46944710314:web:756885233ab032ba3cead3"
};

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

export {
  auth,
};
