import './style.css';

function NotAllowed() {
  return (
    <div className="notAllowed">
      <p>Not Allowed</p>
      <a href="/login">Login</a>
    </div>
  );
}

export default NotAllowed;
