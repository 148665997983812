import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams, Outlet, useLocation } from "react-router-dom";
import DOMPurify from 'dompurify';

// import { getDataAction, getDataClearAction } from '../../../redux/actions/getDataActions';

import backIcon from '../../../img/icons/back.svg';

import './style.css';

function InfoPage(props) {
  const userState = useSelector((state) => state.userReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let location = useLocation();
  let urlParams = useParams();

  const SectionBox = (props) => {
    return (
      <div className={'dgPgSecBx ' + (props.type)}>
        {
          props.type === 'text'
          ? <>
              {
                props.html &&
                <div className="inr" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.html) }} />
              }
            </>
          : null
        }
        {
          props.type === 'image'
          ? <>
              {
                props.url &&
                <img src={props.url} />
              }
            </>
          : null
        }
      </div>
    );
  };

  if (process.env.NODE_ENV !== 'production') {
    console.log('InfoPage urlParams pageid : ', urlParams.pageid);
  }

  if (!urlParams.pageid) return null;

  const { guideData } = props;
  if (!guideData) return null;

  const { hotel_info } = guideData;
  if (!hotel_info) return null;

  if (!hotel_info.pages || !hotel_info.pages.list || !hotel_info.pages.keyValue ||
    !hotel_info.pages.keyValue[urlParams.pageid]) {
    return null;
  }

  const pageData = hotel_info.pages.keyValue[urlParams.pageid];

  let backUrl = `/${urlParams.locationidentifier}/guide/hotel-info`;

  return (
    <div className="mb gde infPg">
      <div className="tpBr">
        <div className="lt">
          <Link to={backUrl}>
            <img src={backIcon} />
          </Link>
        </div>
        <div className="rt">
          <h1>{pageData.name || '-'}</h1>
        </div>
      </div>
      <div className="bdCnt">
        {
          pageData.sections && pageData.sections.length
          ?
            pageData.sections.map((item) => {
              return (
                <SectionBox {...item}
                />
              )
            })
          : null
        }
        {
          pageData.sections && pageData.sections.length
          ?
            pageData.sections.map((item) => {
              return (
                <SectionBox {...item}
                />
              )
            })
          : null
        }
      </div>
    </div>
  );
}

export default InfoPage;
