import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect, useCallback, useRef } from "react";
import { Link, useNavigate, useParams, Outlet, useLocation } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import useOnClickOutside from '../Misc/OutsideClickHook';

import gtLogo from '../../img/gtlogo-color.svg';
import contactNumberInfo from '../../img/icons/contact-number-info.svg';
import moreIcon from '../../img/icons/more.svg';

import './style.css';

function TopBar(props) {
  const userState = useSelector((state) => state.userReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let location = useLocation();
  let urlParams = useParams();

  const [showMenuPanel, setShowMenuPanel] = useState(false);
  const menuPanelRef = useRef();
  useOnClickOutside(menuPanelRef, useCallback(() => setShowMenuPanel(false)));

  const onMenuClick = (e) => {
    setShowMenuPanel(showMenuPanel => !showMenuPanel);
  };

  const TorBarBox = (props) => {
    return (
      <div className="tb">
        <div className="lt">
          <div className="title">
            <img src={gtLogo} />
            <span>{locationData.name || ''}</span>
            {/*locationData.name || ''}
            {locationData.name || ''}
            {locationData.name || ''*/}
          </div>
        </div>
        <div className="rt">
          {/*<img src={contactNumberInfo} />*/}
          <div ref={menuPanelRef} className={'dropdown menuBox ' + (showMenuPanel ? 'open' : '')}>
              <button className="dropbtn" onClick={onMenuClick}>
                <span><img src={moreIcon} className="actionIcon" /></span>
              </button>
              <div className="dropdown-content">
                <a href="tel:+1234567890" target="_blank">Call</a>
                <a href="mailto:abc@guesttouch.com" target="_blank">Email</a>
                {
                  userState.user.loggedIn &&
                  <a href={`/${urlParams.locationidentifier}/engage/logout`}>Logout</a>
                }
              </div>
            </div>
        </div>
      </div>
    );
  };

  if (process.env.NODE_ENV !== 'production') {
    console.log('TopBar urlParams : ', urlParams);
  }

  const { locationData } = props;

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route path="room-info/*" element={<Outlet />}>
            <Route index element={<TorBarBox />} />
        </Route>
        <Route path="hotel-info/*" element={<Outlet />}>
            <Route path=":pageid/*" element={null} />
            <Route index element={<TorBarBox />} />
        </Route>
        <Route path="things-to-do/*" element={<Outlet />}>
            <Route index element={<TorBarBox />} />
        </Route>
        <Route path="places-to-eat/*" element={<Outlet />}>
            <Route index element={<TorBarBox />} />
        </Route>
        <Route path="login/*" element={<Outlet />}>
          <Route path="booking-id/*" element={<TorBarBox />} />
          <Route index element={<TorBarBox />} />
        </Route>
        <Route path=":guestidentifier/*" element={<TorBarBox />} />
        <Route index element={<TorBarBox />} />
      </Route>
    </Routes>
  );


}

export default TopBar;
