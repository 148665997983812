import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect, useRef, useCallback } from "react";
import { Link, useNavigate, useParams, Outlet, } from "react-router-dom";
import emojiData from '@emoji-mart/data';
import Picker from '@emoji-mart/react';

import { editDataAction, editDataClearAction } from '../../../redux/actions/editDataActions';

import useOnClickOutside from '../../Misc/OutsideClickHook';

import emojiIcon from '../../../img/icons/emoji.svg';

import './style.css';

function SendBox(props) {
  const user = useSelector((state) => state.userReducer);
  const engageState = useSelector((state) => state.engageReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();

  const [messageText, setMessageText] = useState('');
  const messageEl = useRef(null);

  const [emojiSelectorOpen, setEmojiSelectorOpen] = useState(false);
  const emojiSelectorRef = useRef();
  useOnClickOutside(emojiSelectorRef, useCallback(() => setEmojiSelectorOpen(false)));

  useEffect(() => {
    if (engageState.guest_data && !engageState.guest_data.sendMessageInProgress &&
      engageState.guest_data.sendMessageStatus &&
      engageState.guest_data.sendMessageStatus.success
    ) {
      if (process.env.NODE_ENV !== 'production') {
        console.log('message sent successfully');
        setMessageText('');
      }
      clearStatus();
    }
  }, [engageState.guest_data]);

  const clearStatus = () => {
    dispatch(editDataClearAction({
      op: 'send_message',
      params: {},
    }));
  };

  const onOpenEmojiSelectorClick = (e) => {
    setEmojiSelectorOpen(emojiSelectorOpen => !emojiSelectorOpen);
  };
  const onEmojiClick = (emoji, e) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onEmojiClick: ', emoji, ' # ', e);
    }
    if (emoji && emoji.native) {
      setMessageText(messageText => `${messageText.substring(0,messageEl.current.selectionStart)}${emoji.native}${messageText.substring(messageEl.current.selectionStart)}`);
    }
  };

  const onMessageChange = (e) => {
    setMessageText(e.target.value);
  };

  const onSendClick = (e) => {
    if (!messageText) return;
    if (engageState.guest_data && engageState.guest_data.sendMessageInProgress) return;

    dispatch(editDataAction({
      op: 'send_message',
      params: {
        location__id: urlParams.locationidentifier, // 'wZYeknJ4QgyGXj7Jm#',
        guest_id: urlParams.guestidentifier, // '610ac959f933820008bb4db9', // '61cacbbe27872a00087a1a27',
        type: 'guest',
        msg: messageText,
        new_msg_id: `${urlParams.guestidentifier}_${new Date().getTime()}`
      },
    }));
    setMessageText('');
  };

  return (
    <div className="sndBx">
      <div className="bd">
        <div className={'lt' + (messageText ? ' hasMsg' : '')}>
          {/*<div className="opts">
            <div className="opIt em">
              <div className={'dd ' + (emojiSelectorOpen ? 'open' : '')} ref={emojiSelectorRef}>
                <button className="dbtn" onClick={onOpenEmojiSelectorClick}>
                  <img src={emojiIcon} />
                </button>
                <div className="ddc">
                  {
                    emojiSelectorOpen &&
                    <div className="emojiBox">
                      <Picker data={emojiData}
                        onEmojiSelect={(emoji, e) => onEmojiClick(emoji, e)}
                      />
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>*/}
          <textarea value={messageText} onChange={onMessageChange} placeholder="Type your message..." ref={messageEl} />
        </div>
        {
          messageText &&
          <div className="rt">
            <div className="sndBtn" onClick={onSendClick}><i className="fa-solid fa-paper-plane"></i></div>
          </div>
        }
      </div>
    </div>
  );
}

export default SendBox;
