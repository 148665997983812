const initialState = {};
// sideBarOpen: true
// locationIdentifier: ''
// groupIdentifier: ''
// screenBeforeSettingsPath: ''
// screenBeforeSettingsName: ''

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    // case 'USER_LOGOUT': {
    //   return initialState;
    // }
    case "ON_SIDEBAR_TOGGLE": {
      const { open } = action.payload;
      return {
        ...state,
        sideBarOpen: open
      };
    }
    // case "SET_LOCATION_IDENTIFIER": {
    //   const { locationIdentifier } = action.payload;
    //   return {
    //     ...state,
    //     locationIdentifier,
    //   };
    // }
    case "SET_GROUP_IDENTIFIER": {
      const { groupIdentifier } = action.payload;
      return {
        ...state,
        groupIdentifier,
      };
    }
    case 'SET_COMMON_PARAMS': {
      const { params } = action.payload;
      return {
        ...state,
        commonParams: state.commonParams
          ? {
            ...state.commonParams,
            ...params,
          } : {
            ...params,
          },
      };
    }
    case "CHANGEAGE": {
      return { ...state, age: action.payload };
    }
  }
  return state;
};
export default appReducer;
