import axios from 'axios';
import { sendEmailAlert } from '../../helpers';

const gtconfig = require('../../gtconfig');



/* ***************************  Start - Handle Pusher Message ****************************** */

export function handleEngagePusherMessage(payload) {
  return function somename(dispatch, getState) {
    if (process.env.NODE_ENV !== 'production') {
      console.log('handleEngagePusherMessage payload: ', payload);
    }
    // dispatch({
    //   type: 'HANDLE_ENGAGE_PUSHER_MESSAGE',
    //   payload,
    // });
    const { type, data, locationidentifier } = payload;

    const stateWrap = getState();
    if (process.env.NODE_ENV !== 'production') {
      console.log('handleEngagePusherMessage getState stateWrap: ', stateWrap);
    }
    const state = { ...stateWrap.engageReducer };
    if (process.env.NODE_ENV !== 'production') {
      console.log('handleEngagePusherMessage getState state: ', state);
    }

    const origPayload = { ...payload };
    // If the guest / outside guest message is received and the guest / outside guest data is not available in store,
    // then fetch it and add it to the store
    if (type === 'guest-message-create' || type === 'outside-guest-message-create') {
      dispatch({
        type: 'HANDLE_ENGAGE_MESSAGE',
        payload:{
          ...payload,
          locationidentifier,
        },
      });
    } else { // end of type === 'guest-message-create' || type === 'outside-guest-message-create'
      dispatch({
        type: 'HANDLE_ENGAGE_MESSAGE',
        payload:{
          ...payload,
          locationidentifier,
        },
      });
    }
  };
}

/* ***************************  End - Handle Pusher Message ****************************** */
