import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams, Outlet, useLocation } from "react-router-dom";

import { getDataAction, getDataClearAction } from '../../../redux/actions/getDataActions';

import backIcon from '../../../img/icons/back.svg';
import nextIcon from '../../../img/icons/next.svg';

import '../style.css';

import GuideTopBar from '../GuideTopBar';

function PageRow(props) {
  return (
    <Link to={`/${props.locationidentifier}/guide/hotel-info/${props.id}`}>
      <div className="sectBx">
        <div className="lt">
          { props.data.name }
        </div>
        <div className="rt">
          <img src={nextIcon} />
        </div>
      </div>
    </Link>
  )
}

function GuidePlacesToEat(props) {
  const userState = useSelector((state) => state.userReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let location = useLocation();
  let urlParams = useParams();

  const getDataClear = () => {
    const params = {
      location__id: urlParams.locationidentifier,
    };
    dispatch(getDataClearAction({
      op: 'get_guide_hotel_info',
      params,
    }));
  };

  const getData = () => {
    const params = {
      location__id: urlParams.locationidentifier,
    };
    if (userState.user && userState.user.uid && userState.user.loggedIn) {
      params.guest_id = userState.user.uid;
    }
    dispatch(getDataAction({
      op: 'get_guide_hotel_info',
      params,
    }));
  };

  // useEffect(() => {
  //   getDataClear();
  //   getData();
  // }, []);


  const { guideData } = props;
  if (!guideData) return null;
  const { hotel_info } = guideData;

  if (hotel_info && hotel_info.getDataInProgress) {
    return (
      <div className="gPnlLdng">
        <i className="fa fa-spinner fa-spin" />
      </div>
    );
  }

  let backUrl = `/${urlParams.locationidentifier}/guide/`;

  return (
    <div className="mb gde htlInf">
      <GuideTopBar
        label={"Restaurants"}
        backUrl={backUrl}
      />
      <div className="bdCnt">
        {/*
          hotel_info && hotel_info.pages && hotel_info.pages.list && hotel_info.pages.keyValue
          ? hotel_info.pages.list.map((pageId) => {
            if (!hotel_info.pages.keyValue[pageId]) return null;
            return (
              <PageRow
                id={pageId}
                data={hotel_info.pages.keyValue[pageId]}
                locationidentifier={urlParams.locationidentifier}
              />
            )
            })
          : null
        */}
      </div>
    </div>
  );
}

export default GuidePlacesToEat;
