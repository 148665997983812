import axios from 'axios';
import { auth } from '../../firebase';

const gtconfig = require('../../gtconfig');



/* ***************************  Start - set logged in ****************************** */

export function setLoggedIn(payload) {
  return function somename(dispatch, getState) {
    dispatch({
      type: 'SET_LOGGED_IN',
      payload,
    });
  };
}

/* ***************************  End - set logged in  ****************************** */


/* ***************************  Start - logout ****************************** */

export function logout(payload) {
  return function somename(dispatch, getState) {
    // dispatch({
    //   type: 'USER_LOGOUT',
    //   payload,
    // });
    const { params } = payload;

    auth.signOut().then(
      () => {
        if (process.env.NODE_ENV !== 'production') {
          console.log('Signed Out'); // comment
        }
        // if (params && params.uid)  logoutUpdateBackend(params.uid);
        dispatch({
          type: 'USER_LOGOUT',
          payload,
        });
      },
      (error) => {
        if (process.env.NODE_ENV !== 'production') {
          console.error('Sign Out Error', error); // comment
        }
      },
    );
  };
}

/* ***************************  End - logout  ****************************** */
