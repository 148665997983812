import React from 'react';
// import ReactDOM from 'react-dom/client';
// import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import { PersistGate } from 'redux-persist/integration/react';
import './index.css';
import App from './App';
// import reportWebVitals from './reportWebVitals';
// import store, { history } from "./redux/store";
import configureStore, { history } from './redux/store';
import { Provider } from "react-redux";

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
const { persistor, store } = configureStore();

root.render(
  <PersistGate persistor={persistor}>
    <Provider store={store}>
      <App />
    </Provider>
  </PersistGate>,
);
// root.render(
//   <React.StrictMode>
//     <Provider store={store}>
//       <App />
//     </Provider>,
//   </React.StrictMode>
// );

// const render = () => {
//   ReactDOM.render(
//     <React.StrictMode>
//       <Provider store={store}>
//         <App />
//       </Provider>,
//     </React.StrictMode>,
//     document.getElementById('root'),
//   );
// };
//
// render();

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <Provider store={store}>
//       <App />
//     </Provider>,
//   </React.StrictMode>
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
