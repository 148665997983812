import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams, Outlet, } from "react-router-dom";

import './style.css';

function Welcome(props) {
  const userState = useSelector((state) => state.userReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();

  useEffect(() => {
  }, []);

  if (process.env.NODE_ENV !== 'production') {
    console.log('Welcome urlParams : ', urlParams);
  }

  const { locationData } = props;
  if (!locationData) return null;

  const cStyle = {};
  cStyle.backgroundImage = locationData.welcome_image ? `url(${locationData.welcome_image})` : '';

  return (
    <div className="mb wlc">
      {/*<div className="tb">{locationData.name || ''}</div>*/}
      <div className="bgWrp" style={cStyle}>
      </div>
    </div>
  );
}

export default Welcome;
