// @flow

import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link, Redirect } from 'react-router-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import queryString from 'query-string';
import axios from 'axios';

import styles from './style.css';

let autoComplete;



function AutoComplete(props) {

  const [placeSearchResults, setPlaceSearchResults] = useState([]);

  const [query, setQuery] = useState("");
  const [googlePlaceDetail, setGooglePlaceDetail] = useState(null);
  const autoCompleteRef = useRef(null);


  useEffect(() => {
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=AIzaSyBhERp79lEMOKYXYeukwVdsg-gT_7A06As&libraries=places&callback=initMap`,
      () => handleScriptLoad(setQuery, setGooglePlaceDetail, autoCompleteRef)
    );
  }, []);

  useEffect(() => {
    console.log('googlePlaceDetail: ', googlePlaceDetail);
  }, [googlePlaceDetail]);

  const initMap = () => {};
  window.initMap = initMap;

  const loadScript = (url, callback) => {
    console.log('loadScript: ', url);
    let script = document.createElement("script");
    script.type = "text/javascript";

    if (script.readyState) {
      script.onreadystatechange = function() {
        if (script.readyState === "loaded" || script.readyState === "complete") {
          script.onreadystatechange = null;
          callback();
        }
      };
    } else {
      script.onload = () => callback();
    }

    script.src = url;
    document.getElementsByTagName("head")[0].appendChild(script);
  };

  function handleScriptLoad(updateQuery, updateGooglePlaceDetail, autoCompleteRef) {
    autoComplete = new window.google.maps.places.Autocomplete(
      autoCompleteRef.current,
      // { types: ["(cities)"], componentRestrictions: { country: "us" } }
      // { types: ["establishment"], fields: ['place_id', 'name', 'types'] }
      { types: ["establishment"] }
    );
    // autoComplete.setFields(["address_components", "formatted_address"]);
    autoComplete.addListener("place_changed", () =>
      handlePlaceSelect(updateQuery, updateGooglePlaceDetail)
    );
  }

  async function handlePlaceSelect(updateQuery, updateGooglePlaceDetail) {
    const addressObject = autoComplete.getPlace();
    const query = `${addressObject.name}, ${addressObject.formatted_address}`;
    updateQuery(query);
    updateGooglePlaceDetail(addressObject);
    props.onLocationSelect(addressObject);
    console.log(addressObject);
  }

  const onHotelNameChange = (e) => {
    const { name, value } = e.target;
    setQuery(value);
    setGooglePlaceDetail(null);
  }

  const renderContent = () => {

    return (
      <div className="trialWrap">
        <input
          ref={autoCompleteRef}
          // onChange={event => setQuery(event.target.value)}
          onChange={onHotelNameChange}
          placeholder="Hotel Name"
          value={query}
        />
      </div>
    );
  }

  return renderContent();

}

export default AutoComplete
