const initialState = {
  window: {
    windowWidth: 0,
    windowHeight: 0,
    leftPanelWidth: 360,
    rightPanelWidth: 360,
  },
};

const windowReducer = (state = initialState, action) => {
  switch (action.type) {
    // case 'USER_LOGOUT': {
    //   return initialState;
    // }
    case 'SET_WINDOW_ATTRIBUTES': {
      let newState = Object.assign({}, state);
      const { windowWidth } = action.payload;
      const { windowHeight } = action.payload;
      const { leftPanelWidth } = action.payload;
      const { rightPanelWidth } = action.payload;
      if (windowWidth) {
        newState = {
          ...newState,
          window: {
            ...newState.window,
            windowWidth,
          },
        };
      }
      if (windowHeight) {
        newState = {
          ...newState,
          window: {
            ...newState.window,
            windowHeight,
          },
        };
      }
      if (leftPanelWidth) {
        newState = {
          ...newState,
          window: {
            ...newState.window,
            leftPanelWidth,
          },
        };
      }
      if (rightPanelWidth) {
        newState = {
          ...newState,
          window: {
            ...newState.window,
            rightPanelWidth,
          },
        };
      }
      return newState;
    }
    default:
  }
  return state;
}
export default windowReducer;
