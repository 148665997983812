import { useSelector, useDispatch } from "react-redux";
import { useState, useRef, useEffect } from "react";
import { Link, useNavigate, useParams, Outlet, useLocation } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import axios from 'axios';
import { auth } from '../../../firebase';
import { signInWithEmailAndPassword, signInWithCustomToken } from "firebase/auth";

// import { changeUsersName } from "../../redux/actions/changeUsersName";
import { setLoggedIn } from "../../../redux/actions/miscActions";
import { getDataAction } from '../../../redux/actions/getDataActions';

import About from '../../About';
import Login from '../Login';
import LoginBookingId from '../Login/LoginBookingId';

import gtLogo from '../../../img/gtlogo-color.svg';

import './style.css';

const gtconfig = require('../../../gtconfig');

function LoginWrapper() {
  const user = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let location = useLocation();
  let urlParams = useParams();

  const loginFormUsername = useRef(null);
  // const loginFormPassword = useRef(null);
  const [loginForm, setLoginForm] = useState({
    username: '',
    // password: '',
    usernameError: '',
    // passwordError: '',
    loginInProgress: false,
  });
  const [loginError, setLoginError] = useState(false);

  let params = useParams();


  // const onAuthStateChange = () => {
  //   if (process.env.NODE_ENV !== 'production') {
  //     console.log('Login onAuthStateChange');
  //   }
  //   return auth.onAuthStateChanged((authUser) => {
  //     if (process.env.NODE_ENV !== 'production') {
  //       console.log('Login onAuthStateChanged: ', authUser, ' # ', new Date().getTime()); // comment
  //     }
  //     if (authUser) {
  //       // cookies.set('_lastGtLogin', new Date().getTime(), { path: '/', domain: 'localhost' });
  //       // cookies.set('_lastGtLogin', new Date().getTime(), { path: '/', domain: '.guesttouch.com' });
  //       dispatch(setLoggedIn({
  //         uid: authUser.uid,
  //         loggedIn: true,
  //         loggedOut: false,
  //       }));
  //       navigate(`/${urlParams.locationidentifier}/engage/${authUser.uid}`);
  //     }
  //   });
  // };

  useEffect(() => {
    if (location.pathname.indexOf('/login') === -1) {
      navigate(`/${urlParams.locationidentifier}/engage/login`);
    }
  }, []);

  // useEffect(() => {
  //   // if (cookies.get('_lastGtLogin', { domain: '.guesttouch.com'})) {
  //   //   if (process.env.NODE_ENV !== 'production') {
  //   //     console.log('_lastGtLogin: ', cookies.get('_lastGtLogin', { domain: '.guesttouch.com'}));
  //   //   }
  //     const unsubscribe = onAuthStateChange();
  //     return () => {
  //       unsubscribe();
  //     };
  //   // }
  // }, []);


  if (process.env.NODE_ENV !== 'production') {
    console.log('LoginWrapper urlParams: ', urlParams);
  }

  return (
    <div className="mb eng">
      <div className="enWrp">
        <Routes>
          <Route element={<Outlet />}>
            <Route path="booking-id" element={<LoginBookingId />} />
            <Route index element={<Login />} />
          </Route>
        </Routes>
      </div>
    </div>
  );
}

export default LoginWrapper;
