import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams, Outlet, useLocation } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import { auth } from '../../firebase';
import { signInWithEmailAndPassword, signInWithCustomToken } from "firebase/auth";

import { getDataAction } from '../../redux/actions/getDataActions';
import { setLoggedIn } from "../../redux/actions/miscActions";

import About from '../About';
import Engage from '../Engage';
import LoginWrapper from '../Engage/LoginWrapper';
import Logout from '../Engage/Logout';


function EngageWrapper(props) {
  const userState = useSelector((state) => state.userReducer);
  const engageState = useSelector((state) => state.engageReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();
  let location = useLocation();

  const onAuthStateChange = () => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('EngageWrapper onAuthStateChange');
    }
    return auth.onAuthStateChanged((authUser) => {
      if (process.env.NODE_ENV !== 'production') {
        console.log('EngageWrapper onAuthStateChanged: ', authUser, ' # ', new Date().getTime()); // comment
      }
      if (process.env.NODE_ENV !== 'production') {
        console.log('location pathaname: ', location.pathname);
      }
      // get guest identifier
      let guestId = '';
      let { pathname } = location;
      if (pathname) {
        pathname = pathname.split(urlParams.locationidentifier)[1];
        if (process.env.NODE_ENV !== 'production') {
          console.log('EngageWrapper pathname: ', pathname);
        }
        if (pathname.indexOf('/engage/') > -1) {
          pathname = pathname.split('/engage/')[1];
          if (process.env.NODE_ENV !== 'production') {
            console.log('EngageWrapper pathname:: ', pathname);
          }
          pathname = pathname.split('/')[0];
        }
        if (process.env.NODE_ENV !== 'production') {
          console.log('EngageWrapper pathname::: ', pathname);
        }
        guestId = pathname;
      }
      if (!userState.user.loggedOut) {
        if (authUser) {
          if (process.env.NODE_ENV !== 'production') {
            console.log('EngageWrapper onAuthStateChanged user: ', authUser.uid);
          }
          // cookies.set('_lastGtLogin', new Date().getTime(), { path: '/', domain: 'localhost' });
          // cookies.set('_lastGtLogin', new Date().getTime(), { path: '/', domain: '.guesttouch.com' });
          if (authUser.uid.indexOf('_') > -1) {
            let uid = authUser.uid.split('_')[1];
            if (guestId && guestId !== uid) {

            } else {
              dispatch(setLoggedIn({
                uid,
                loggedIn: true,
                loggedOut: false,
              }));
              navigate(`/${urlParams.locationidentifier}/engage/${uid}`); // 61cacbbe27872a00087a1a27
              // navigate(`/${urlParams.locationidentifier}/engage/61cacbbe27872a00087a1a27`);
            }
          }
        }
      }
    });
  };

  useEffect(() => {
    // if (cookies.get('_lastGtLogin', { domain: '.guesttouch.com'})) {
    //   if (process.env.NODE_ENV !== 'production') {
    //     console.log('_lastGtLogin: ', cookies.get('_lastGtLogin', { domain: '.guesttouch.com'}));
    //   }
      const unsubscribe = onAuthStateChange();
      return () => {
        unsubscribe();
      };
    // }
  }, []);

  if (process.env.NODE_ENV !== 'production') {
    console.log('EngageWrapper urlParams : ', urlParams);
  }

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route path="about" element={<About />} />
        <Route path="logout" element={<Logout />} />
        <Route path="login/*" element={<LoginWrapper />} />
        {
          userState.user.loggedIn &&
          <Route path=":guestidentifier/*" element={<Engage />} />
        }
        <Route index element={<LoginWrapper />} />
      </Route>
    </Routes>
  );

  // return (
  //   <div className="mb eng">
  //     <div className="enWrp">
  //       <ConversationBox />
  //       <SendBox />
  //     </div>
  //   </div>
  // );
}

export default EngageWrapper;
