import { useSelector, useDispatch } from "react-redux";
import { useState, useRef, useEffect } from "react";
import { Link, useNavigate, useParams, Outlet, useLocation } from "react-router-dom";
import axios from 'axios';
import { auth } from '../../../firebase';
import { signInWithEmailAndPassword, signInWithCustomToken } from "firebase/auth";

// import { changeUsersName } from "../../redux/actions/changeUsersName";
import { setLoggedIn } from "../../../redux/actions/miscActions";
import { getDataAction } from '../../../redux/actions/getDataActions';

import gtLogo from '../../../img/gtlogo-color.svg';

import './style.css';

const gtconfig = require('../../../gtconfig');

function Login() {
  const user = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let location = useLocation();
  let urlParams = useParams();

  const loginFormUsername = useRef(null);
  // const loginFormPassword = useRef(null);
  const [loginForm, setLoginForm] = useState({
    username: '',
    // password: '',
    usernameError: '',
    // passwordError: '',
    loginInProgress: false,
  });
  const [loginError, setLoginError] = useState(false);

  let params = useParams();


  // const onAuthStateChange = () => {
  //   if (process.env.NODE_ENV !== 'production') {
  //     console.log('Login onAuthStateChange');
  //   }
  //   return auth.onAuthStateChanged((authUser) => {
  //     if (process.env.NODE_ENV !== 'production') {
  //       console.log('Login onAuthStateChanged: ', authUser, ' # ', new Date().getTime()); // comment
  //     }
  //     if (authUser) {
  //       // cookies.set('_lastGtLogin', new Date().getTime(), { path: '/', domain: 'localhost' });
  //       // cookies.set('_lastGtLogin', new Date().getTime(), { path: '/', domain: '.guesttouch.com' });
  //       dispatch(setLoggedIn({
  //         uid: authUser.uid,
  //         loggedIn: true,
  //         loggedOut: false,
  //       }));
  //       navigate(`/${urlParams.locationidentifier}/engage/${authUser.uid}`);
  //     }
  //   });
  // };

  useEffect(() => {
    if (location.pathname.indexOf('/login') === -1) {
      navigate(`/${urlParams.locationidentifier}/engage/login`);
    }
  }, []);

  // useEffect(() => {
  //   // if (cookies.get('_lastGtLogin', { domain: '.guesttouch.com'})) {
  //   //   if (process.env.NODE_ENV !== 'production') {
  //   //     console.log('_lastGtLogin: ', cookies.get('_lastGtLogin', { domain: '.guesttouch.com'}));
  //   //   }
  //     const unsubscribe = onAuthStateChange();
  //     return () => {
  //       unsubscribe();
  //     };
  //   // }
  // }, []);


  const renderLoginOptions = () => {
    return (
      <div className="lgOptWrap">
        <p className="ttl">Login to start chatting</p>
        {/*<p className="subTtl">To continue live chat, login with any of the following methods</p>*/}
        <p className="subTtl">To continue live chat, please login.</p>
        <div className="lgOpts">
          <Link className="etry" to ={`/${urlParams.locationidentifier}/engage/login/booking-id`}>
            Booking Id
          </Link>
          {/*<Link className="etry" to ={`/${urlParams.locationidentifier}/engage/login/email`}>
            Email Address
          </Link>
          <Link className="etry" to ={`/${urlParams.locationidentifier}/engage/login/phone`}>
            Phone
          </Link>*/}
        </div>
      </div>
    );
  };

  if (process.env.NODE_ENV !== 'production') {
    console.log('Login urlParams: ', urlParams);
  }

  return (
    <div className="mb eng">
      <div className="enWrp">
        { renderLoginOptions() }
      </div>
    </div>
  );
}

export default Login;
