import moment from 'moment';
import axios from 'axios';

const emojiRegex = require('emoji-regex');

const gtconfig = require('../gtconfig');

export const formatDate = (date) => {
  return moment.utc(date).local().format('Do MMM YYYY');
};
export const formatDateTime = (date) => {
  return moment.utc(date).local().format('Do MMM YYYY hh:mm A');
};
export const formatDateTimeOrig = (date) => {
  return date;
};

export const formatDateSpl = (date) => {
  let fmt = 'Do MMM YYYY';
  if (!moment().isSame(moment(date), 'year')) {
    fmt = 'MM/DD/YY';
  } else {
    fmt = 'MMM DD';
  }
  return moment.utc(date).local().format(fmt);
};

export const formatDateStrToStr = (dateStr) => {
  if (!dateStr) return null;
  return moment(dateStr,'YYYY-MM-DD').format('Do MMM YYYY');
};

export function formatMessageDate(date) {
  try {
    if (date) {
      const date_diff = moment(new Date()).diff(moment(date), 'seconds');
      if ((date_diff / 60) > (12 * 60)) {
        return moment.utc(date).local().format('Do MMM YYYY');
      }
      if ((date_diff / 60) > 60) { // more than 60 mins.... show hours
        return moment.utc(date_diff * 1000).format('h') + ' hrs ' + moment.utc(date_diff * 1000).format('m') + ' mins ago';
      } else if (date_diff > 60) { // more than 60 secs... show mins
        if (date_diff > 1) {
          return moment.utc(date_diff * 1000).format('m') + ' mins ago';
        }
        return moment.utc(date_diff * 1000).format('m') + ' min ago';
      }
      if (date_diff > 1) {
        return moment.utc(date_diff * 1000).format('s') + ' secs ago';
      }
      return 'Just now';
    }
  } catch (e) {
    //
  }
  return date;
}

export function formatDateChange(date) {
  try {
    if (date) {
      const date_diff = moment(new Date()).diff(moment(date), 'seconds');
      if ((date_diff / 60) > (24 * 60)) {
        return moment.utc(date).local().format('Do MMM YYYY');
      }
      return 'Today';
    }
  } catch (e) {
    //
  }
  return date;
}


function sendEmailAlertLocal(params) {
  const url = gtconfig.EMAIL_ALERT_URL;
  const opdata = {};
  opdata.params = params;
  const postData = {
    op: 'send_email_alert',
    opdata,
  };
  if (process.env.NODE_ENV !== 'production') {
    console.log('postData: ', postData);
  }

  const headers = {
    'Content-type': 'application/json',
    'x-api-key': gtconfig.EMAIL_ALERT_API_KEY,
  };
  const axiosData = {
    // nkcheck - needed
    // gtp: CryptoJS.AES.encrypt(JSON.stringify(postData), gtconfig.EMAIL_ALERT_URL_GT_BASIC_KEY).toString(),
  };

  axios.post(url, axiosData, { headers }).then((response) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('sendEmailAlertLocal response: ', response.data);
    }
    const { status } = response.data;
    if (status !== 'success') {
      if (process.env.NODE_ENV !== 'production') {
        console.log('sendEmailAlertLocal success: ', response);
      }
    }
  })
    .catch((err) => {
      if (process.env.NODE_ENV !== 'production') {
        console.log('sendEmailAlertLocal err--------------------: ', err);
      }
    });
}

export function sendEmailAlert(params) {
  sendEmailAlertLocal(params);
}
