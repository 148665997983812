import { useSelector, useDispatch } from "react-redux";
import { useState, useRef, useEffect } from "react";
import { Link, useNavigate, useParams, Outlet, } from "react-router-dom";

import { setLoggedIn, logout } from "../../../redux/actions/miscActions";

import './style.css';

function Logout() {
  const userState = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let params = useParams();
  let urlParams = useParams();

  // useEffect(() => {
  //   dispatch(logout({
  //     params: {
  //       uid: userState.user.uid,
  //     },
  //   }));
  // }, []);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('Logout user changed: ', userState.user);
    }
    dispatch(setLoggedIn({
      loggedIn: false,
      loggedOut: true,
    }));
    dispatch(logout({
      params: {
        uid: userState.user.uid,
      },
    }));
    // if (!userState.user || !Object.keys(userState.user).length) {
    //   console.log('Logout navigate: ', `/${urlParams.locationidentifier}/engage/login`);
    //   navigate(`/${urlParams.locationidentifier}/engage/login`);
    // }
    navigate(`/${urlParams.locationidentifier}/engage/login`);
  }, [userState.user.uid]);

  if (process.env.NODE_ENV !== 'production') {
    console.log('Logout');
  }

  return (
    <div className="mb eng">
      <div className="enWrp">
      </div>
    </div>
  )
}

export default Logout;
